import bilAPI from '../api';

export const getLessonContent = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/lessons/${uuid}/content/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const getLessons = ({ uuid, section, order }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/courses/lessons/${uuid ? uuid + '/' : ''}`, {
          params: { section, order },
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const completeLesson = async ({ lessonUuid, userUuid }) => {
  try {
    const existingProgress = await getUserLessonProgress({ lessonUuid, userUuid });

    const data = {
      status: 2,
    };

    if (existingProgress) {
      console.log('Обновление существующей записи прогресса урока:', existingProgress.uuid);
      const res = await bilAPI.patch(`/courses/user_progress/${existingProgress.uuid}/`, data);
      console.log('Ответ после PATCH:', res.data);
      return res.data;
    } else {
      const postData = {
        lesson: lessonUuid,
        status: 2,
        user: userUuid,
      };
      console.log('Создание новой записи прогресса урока:', postData);
      const res = await bilAPI.post('/courses/user_progress/', postData);
      console.log('Ответ после POST:', res.data);
      return res.data;
    }
  } catch (error) {
    console.error('Ошибка при завершении урока:', error);
    if (error.response && error.response.data) {
      console.error('Детали ошибки:', error.response.data);
    }
    throw error.response?.data || error.message;
  }
};

export const getCompletedLessons = async (userUuid) => {
  try {
    const res = await bilAPI.get('/courses/user_progress/', {
      params: { user: userUuid, status: 2 },
    });
    return res.data.results.map((progress) => progress.lesson);
  } catch (error) {
    console.error('Ошибка при получении завершённых уроков:', error);
    throw error.response?.data || error.message;
  }
};

export const getUserLessonProgress = async ({ lessonUuid, userUuid }) => {
  try {
    const res = await bilAPI.get('/courses/user_progress/', {
      params: { lesson: lessonUuid, user: userUuid },
    });
    return res.data.results.length > 0 ? res.data.results[0] : null;
  } catch (error) {
    console.error('Ошибка при получении прогресса урока:', error);
    throw error.response?.data || error.message;
  }
};


export const postLesson = ({ name, section, duration, }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/lessons/`, {
          name,
          section,
          order: 0, 
          duration,
           
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const patchLesson = ({ uuid, name, duration,  }) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/courses/lessons/${uuid}/`, {
          name,
          duration,
          
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const deleteLesson = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .delete(`/courses/lessons/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const postIncreaseLessonOrder = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/lessons/${uuid}/increase_order/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};


export const postDecreaseLessonOrder = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/courses/lessons/${uuid}/decrease_order/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const updateCourseProgress = async ({ lesson, status, user }) => {
  try {
    const payload = {
      lesson: lesson,  
      status: status,   
      user: user,       
    };
    console.log('Отправляем данные для обновления прогресса курса:', payload);

    const res = await bilAPI.post('/courses/user_progress/', payload);
    return res.data;
  } catch (error) {
    if (error.response) {
      console.error('Ошибка в updateCourseProgress:', error.response.data);
      throw error.response.data;
    } else {
      console.error('Ошибка в updateCourseProgress:', error.message);
      throw error.message;
    }
  }
};
