/* eslint-disable no-unused-vars */
import axios from 'axios';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import bilAPI from '../../services/api';
import { UserContext } from '../contexts/UserContext';
import TokenService from '../../services/token.service';
import { PreferredPairContext } from '../contexts/PreferredPairContext';
import { getSkillPoint } from '../../services/projects/skillsService';

const baseURL = process.env.REACT_APP_API_URL;

export default function useAuth() {
  const navigate = useNavigate();

  const { user, setUser } = useContext(UserContext);
  const { setPair } = useContext(PreferredPairContext);
  const [error, setError] = useState(null);

  const setUserContext = async (token) => {
    setUser(token);

    return await axios
      .get(baseURL + '/auth/account_info/', {
        headers: {
          Authorization: 'Bearer ' + token,
        },
      })
      .then((response) => {
        setUser(response.data);
        if (response.data.root_organization === 'APA') {
          navigate({
            pathname: `/tests`,
            state: { user: user },
          });
        }      
        else if (response.data.roles.some((role) => role.name === 'STUDENT')) {
          navigate({
            pathname: `/home`,
            state: { user: user },
          });
        }
        else if (
          response.data.roles.some((role) => role.name === 'PROJECTADMIN') ||
          response.data.roles.some((role) => role.name === 'PROJECTREVIEWER')
        ) {
          // console.log('response', response);
          navigate({
            pathname: `/home`,
            state: { user: user },
          });
        }
      })
      .catch((err) => {
        setError(err.response.data);
      });
  };

  const loginUser = async (data) => {
    const { username, password } = data;
    return axios
      .post(baseURL + '/auth/login/', {
        username,
        password,
      })
      .then(async (response) => {
        // console.log(response.data.access);
        TokenService.updateLocalAccessToken(response.data.access);
        bilAPI.defaults.headers['Authorization'] =
          'Bearer ' + response.data.access;
        TokenService.updateLocalRefreshToken(response.data.refresh);
        await setUserContext(response.data.access);

        getSkillPoint({ uuid: null, level: 2 }).then((res) => {
          const preferredPair = res?.results?.find(
            (p) => p?.is_prefered === true,
          );
          if (preferredPair?.uuid) {
            setPair(preferredPair);
          }
        });
      })
      .catch((err) => {
        setError(err.response);
      });
  };

  return {
    loginUser,
    setUserContext,
    error,
  };
}
