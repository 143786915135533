import React, { useState } from "react";

export default function Training() {
  const [activeTab, setActiveTab] = useState("Видео");

  return (
    <div className="flex flex-col items-center bg-gray-100 min-h-screen space-y-6">
      {/* Видео с заголовком и описанием */}
      <div className="w-full max-w-5xl bg-white shadow-md rounded-lg overflow-hidden mt-6">


        {/* Навигация по видео */}
        <div className="flex justify-between items-center bg-gray-100 px-4 py-4 border-b">
          <button
            className="text-gray-600 hover:text-gray-800 flex items-center gap-2 transition-colors duration-200"
            onClick={() => console.log("Предыдущее видео")}
          >
            <span>&larr; Предыдущее видео</span>
          </button>

          <div className="flex flex-col items-center">
            <span className="text-sm text-gray-500 font-medium">Блок 1</span>
            <span className="text-base font-semibold text-gray-700">Видео 1</span>
          </div>

          <button
            className="text-gray-600 hover:text-gray-800 flex items-center gap-2 transition-colors duration-200"
            onClick={() => console.log("Следующее видео")}
          >
            <span>Следующее видео &rarr;</span>
          </button>
        </div>

        {/* Видео */}
        <div className="w-full bg-black">
          <iframe
            className="w-full h-64 sm:h-80"
            src="https://www.youtube.com/embed/nVLIaQuVS4E"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>

        {/* Заголовок и описание */}
        <div className="p-6">
          <div className="flex items-center gap-2 mb-4">
            <span className="bg-yellow-300 text-black text-xs px-2 py-1 rounded-full">
              ★ 5
            </span>
            <span className="bg-purple-100 text-purple-600 text-xs px-2 py-1 rounded-full">
              Искусство
            </span>
            <span className="bg-blue-100 text-blue-600 text-xs px-2 py-1 rounded-full">
              Погулять
            </span>
            <span className="text-xs text-gray-500">11 часов</span>
          </div>
          <h1 className="text-2xl font-bold mb-2">
            Архитектурный гид по европейским столицам. От Рима до Москвы
          </h1>
          <p className="text-gray-600 mb-4">
            Главное об архитектуре 6 столиц, чтобы составить список мест для
            посещения. Вместо книги Дэвида Уткина и архитектурных путеводителей.
          </p>
          <button className="flex items-center text-gray-600 hover:text-gray-800">
            ♡ В избранное
          </button>
        </div>
      </div>

      {/* Прогресс */}
      <div className="w-full max-w-5xl bg-white shadow-md rounded-lg p-6">
        <h2 className="text-lg font-semibold mb-4">Прогресс</h2>
        <div className="flex flex-wrap gap-4">
          <div className="flex items-center gap-2">
            <span className="bg-blue-500 text-white text-sm px-3 py-1 rounded-full">
              0/31
            </span>
            <span>Видео просмотрено</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="bg-pink-500 text-white text-sm px-3 py-1 rounded-full">
              0/6
            </span>
            <span>Карточек открыто</span>
          </div>
          <div className="flex items-center gap-2">
            <span className="bg-purple-500 text-white text-sm px-3 py-1 rounded-full">
              0/1
            </span>
            <span>Экзаменов сдано</span>
          </div>
        </div>
        <div className="mt-4 w-full bg-gray-200 rounded-full h-2">
          <div
            className="bg-blue-500 h-2 rounded-full"
            style={{ width: "46%" }} // Пример прогресса
          ></div>
        </div>
      </div>

      {/* Блоки и видео */}
      <div className="w-full max-w-5xl bg-white shadow-md rounded-lg p-6">
        {/* Вкладки */}
        <div className="flex bg-gray-100 p-2 rounded-lg mb-4">
          {["Видео", "Автор", "Сертификат"].map((tab) => (
            <button
              key={tab}
              onClick={() => setActiveTab(tab)}
              className={`flex-grow text-center px-6 py-2 rounded-lg text-sm font-medium transition-colors ${
                activeTab === tab
                  ? "bg-white text-black shadow"
                  : "bg-transparent text-gray-500"
              }`}
            >
              {tab}
            </button>
          ))}
        </div>

        {/* Содержимое вкладок */}
        {activeTab === "Видео" && (
          <div>
            <h2 className="text-lg font-semibold mb-4">Блоки и видео</h2>
            <div className="flex flex-wrap gap-2 mb-4">
              <button className="bg-gray-200 px-4 py-2 rounded-lg font-medium">
                Блок 1
              </button>
              <button className="bg-gray-200 px-4 py-2 rounded-lg font-medium">
                Блок 2
              </button>
              <button className="bg-gray-200 px-4 py-2 rounded-lg font-medium">
                Блок 3
              </button>
              <button className="bg-gray-200 px-4 py-2 rounded-lg font-medium">
                Блок 4
              </button>
              <button className="bg-gray-200 px-4 py-2 rounded-lg font-medium">
                Блок 5
              </button>
              <button className="bg-gray-200 px-4 py-2 rounded-lg font-medium">
                Блок 6
              </button>
            </div>
            <h2 className="text-lg font-semibold mb-4">
              Российская столица — Москва
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="border p-4 rounded-lg shadow hover:bg-gray-50 cursor-pointer">
                <h3 className="font-bold">1. Основание Москвы</h3>
                <p className="text-gray-500">22 минуты</p>
              </div>
              <div className="border p-4 rounded-lg shadow hover:bg-gray-50 cursor-pointer">
                <h3 className="font-bold">2. Московский Кремль</h3>
                <p className="text-gray-500">21 минута</p>
              </div>
              <div className="border p-4 rounded-lg shadow hover:bg-gray-50 cursor-pointer">
                <h3 className="font-bold">3. Архитектура</h3>
                <p className="text-gray-500">20 минута</p>
              </div>
              <div className="border p-4 rounded-lg shadow hover:bg-gray-50 cursor-pointer">
                <h3 className="font-bold">4. Большой переезд Москвы</h3>
                <p className="text-gray-500">17 минута</p>
              </div>
              <div className="border p-4 rounded-lg shadow hover:bg-gray-50 cursor-pointer">
                <h3 className="font-bold">5. Столица империи</h3>
                <p className="text-gray-500">19 минута</p>
              </div>
              <div className="border p-4 rounded-lg shadow hover:bg-gray-50 cursor-pointer">
                <h3 className="font-bold">6. Российская столица — Москва</h3>
                <p className="text-gray-500">30 минута</p>
              </div>
            </div>
          </div>
        )}

        {activeTab === "Автор" && (
          <div className="flex flex-col items-start p-6">
            {/* Изображение и заголовок */}
            <div className="flex items-center mb-4">
              <img
                src="https://kuban24.tv/wp-content/uploads/2024/04/photo_2024-04-17_13-35-52.jpg"
                alt="Аватар автора"
                className="w-20 h-20 rounded-full mr-4"
              />
              <div>
                <h3 className="text-xl font-bold">Александр Дединкин</h3>
                <p className="text-gray-500">
                  Искусствовед и психолог, Институт им. И. Е. Репина, СПбГУ
                </p>
              </div>
            </div>

            {/* Описание */}
            <p className="text-gray-600 leading-relaxed">
              Для меня история архитектуры — это не просто работа, а моя страсть и образ
              жизни. Я посвятил ей много лет жизни, начиная с обучения в
              Санкт-Петербургской академии художеств, и продолжаю уделять ей все
              свободное время. Я с удовольствием делюсь знаниями о великих творцах,
              стилях и шедеврах мировой архитектуры, читаю лекции и провожу экскурсии,
              веду телеграм-канал, где рассказываю о малоизвестных страницах архитектуры
              Петербурга. Моя цель — показать красоту в обыденных местах и открыть города
              с новых ракурсов. Мой подход к материалу отличается тем, что у меня есть
              психологическое образование, поэтому я также говорю об искусстве с точки
              зрения его восприятия. В лекциях я использую множество примеров из жизни,
              чтобы продемонстрировать, что архитектура — это удивительное искусство,
              которое может изменить ваш взгляд на мир, путешествия и место, где вы
              живете.
            </p>
          </div>
        )}

        {activeTab === "Сертификат" && (
  <div className="flex flex-col items-center">
    <h2 className="text-lg font-semibold mb-4">Сертификат</h2>
    <img
      src="https://directacademia.ru/images/tild3932-3432-4334-b633-316431313139__4.jpg"
      alt="Сертификат"
      className="w-full max-w-md rounded-lg shadow-lg"
    />
    <p className="text-gray-600 mt-4 text-center">
      Это ваш сертификат, подтверждающий успешное прохождение курса.
    </p>
  </div>
)}

      </div>
    </div>
  );
}
