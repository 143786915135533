import React from 'react';
import Add from './BREAD/Add';
import { EyeIcon } from '@heroicons/react/24/outline';
import { useNavigate } from 'react-router-dom';
import Delete from './BREAD/Delete';
import Edit from './BREAD/Edit';
import { useTranslation } from 'react-i18next';

const ContentCreater = ({ data, refreshData }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className='flex flex-col'>
      <div className='flex flex-row justify-start'>
        <Add refreshData={refreshData} />
      </div>
      <div className='flex flex-row gap-2 flex-wrap'>
        {data?.map((course, i) => {
          return (
            <div
              key={course.uuid}
              className='flex flex-col border rounded-lg mt-2 w-full md:max-w-[18rem] min-w-[12rem]'
            >
              <div className='relative p-4 bg-slate-100 h-full'>
                {`${i + 1}. ${course.name}`}

                <div className='flex flex-row gap-2 absolute right-2 -bottom-4'>
                  <EyeIcon
                    onClick={() =>
                      navigate({
                        pathname: `/courses/${course.uuid}`,
                      })
                    }
                    className='w-8 h-8 p-1 text-red-400 border rounded-full bg-white hover:bg-slate-200 cursor-pointer'
                  />
                  <Edit refreshData={refreshData} course={course} />

                  <Delete course={course} refreshData={refreshData} />
                </div>
              </div>
              <div className='flex flex-row border-t p-4 gap-4 text-center'>
                <div>
                  <div className='text-xl font-medium'>{course?.sections}</div>
                  <div className='text-xs text-gray-600'>{t("courses.sections")}</div>
                </div>
                <div>
                  <div className='text-xl font-medium'>{course?.lessons}</div>
                  <div className='text-xs text-gray-600'>{t("courses.lessons")}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default ContentCreater;
