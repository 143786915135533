import { format } from 'date-fns';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import TextArea from '../../../../../../components/forms/Inputs/TextArea';
import DatePicker from '../../../../../../components/forms/DatePicker/DatePicker';
import Input from '../../../../../../components/forms/Inputs/Input';
import { PlusCircleIcon, PlusSmallIcon } from '@heroicons/react/24/outline';
import SidebarModal from '../../../../../../components/modals/SidebarModal/SidebarModal';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SidebarContext } from '../../../../../../utils/contexts/SidebarContext';
import { toast } from 'react-toastify';

const AddSubTask = ({ data, setData, name }) => {
  const generateTemporaryId = (length = 14) => {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };
  const { id, setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const [t] = useTranslation();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(new Date());

  const [period, setPeriod] = useState(1);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const reset = () => {
    setTitle('');
    setDescription('');
    setPeriod(1);
  };

  const onClose = () => {
    reset();
    setId('');
  };

  useEffect(() => {
    let task = data?.body?.find((t) => t.name === name);

    if (task?.subtasks?.lenght) {
      let subtask = task?.subtasks[task?.subtasks?.length - 1];
      setStartDate(
        new Date(
          new Date(subtask.start_date)?.getTime() +
            subtask.time?.replace('day', '')?.replace('s', '') *
              24 *
              60 *
              60 *
              1000,
        ),
      );
    } else {
      setStartDate(
        new Date(
          new Date(task.start_date)?.getTime() +
            task.time?.replace('day', '')?.replace('s', '') *
              24 *
              60 *
              60 *
              1000,
        ),
      );
    }
  }, [data?.body, name]);

  useEffect(() => {
    if (id !== uuid) {
      reset();
    }
  }, [id, uuid]);

  return (
    <>
      <PlusCircleIcon
        className='h-5 text-primary cursor-pointer'
        onClick={() => {
          setId(uuid);
        }}
      />

      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.create_new_task')}
        HeaderIcon={PlusSmallIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          <Input
            label={t('roadmap.title')}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            required
          />
          <DatePicker
            date={startDate}
            setDate={(d) => {
              // if (endDate < d && endDate) {
              //   toast.error(t('roadmap.reversed_date_error'));
              //   return;
              // }
              setStartDate(d);
            }}
            label={t('roadmap.start_date')}
            required
          />
          <TextArea
            label={t('Description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
          <Input
            type='Number'
            label={t('Period')}
            min='1'
            onChange={(e) => {
              setPeriod(e.target.value);
            }}
            value={Number(period)}
          />

          <div className='flex flex-row justify-center mt-4'>
            <Button
              text={t('roadmap.add')}
              loading={loadingSubmit}
              onClick={() => {
                if (period <= 0) {
                  toast.error("The period can't be less than 1 day");
                  return;
                }

                const new_start_date = format(startDate, 'yyyy-MM-dd');
                const newArray = data?.body?.map((item) => {
                  if (item?.name === name) {
                    let new_subtasks = [
                      ...item?.subtasks,
                      {
                        id: generateTemporaryId(14),
                        name: title,
                        description,
                        start_date: new_start_date,
                        time: `${Number(period)} day${period > 1 ? 's' : ''}`,
                      },
                    ].sort((a, b) => (a.start_date > b.start_date ? 1 : -1));

                    let minStartDate = new_subtasks.reduce(
                      (prev, cur) =>
                        prev < cur.start_date ? prev : cur.start_date,
                      new_subtasks[0]?.start_date,
                    );
                    let newTime = Math.floor(
                      (new_subtasks.reduce((prev, next) => {
                        let next_deadline =
                          new Date(next.start_date).getTime() +
                          Number(
                            next?.time?.replace('day', '')?.replace('s', ''),
                          ) *
                            24 *
                            60 *
                            60 *
                            1000;
                        return prev > next_deadline ? prev : next_deadline;
                      }, 0) -
                        new Date(minStartDate)) /
                        (24 * 60 * 60 * 1000),
                    );

                    return {
                      ...item,
                      start_date: minStartDate,

                      time: `${newTime} day${newTime > 1 ? 's' : ''}`,

                      subtasks: new_subtasks,
                    };
                  } else {
                    return item;
                  }
                });

                const newRoadmap = { ...data, body: newArray };
                setData(newRoadmap);
                onClose();
              }}
            />
          </div>
        </div>
      </SidebarModal>
    </>
  );
};

export default AddSubTask;
