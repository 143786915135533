import { format } from 'date-fns';
import { Button } from '../../../../../../components/forms/Buttons/Button';
import TextArea from '../../../../../../components/forms/Inputs/TextArea';
import DatePicker from '../../../../../../components/forms/DatePicker/DatePicker';
import Input from '../../../../../../components/forms/Inputs/Input';
import { PlusSmallIcon } from '@heroicons/react/24/outline';
import SidebarModal from '../../../../../../components/modals/SidebarModal/SidebarModal';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SidebarContext } from '../../../../../../utils/contexts/SidebarContext';
import { addDaysToCurrentDate } from '../../../../../../utils/helpers/helpers';

const AddTask = ({ data, setData }) => {
  const generateTemporaryId = (length = 14) => {
    const chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += chars.charAt(Math.floor(Math.random() * chars.length));
    }
    return result;
  };
  const { id, setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);

  const [t] = useTranslation();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [startDate, setStartDate] = useState(addDaysToCurrentDate());
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const reset = () => {
    setTitle('');
    setDescription('');
  };

  const onClose = () => {
    reset();
    setId('');
  };

  useEffect(() => {
    if (data.body?.length) {
      let task = data?.body[data.body?.length - 1];
      setStartDate(
        new Date(
          new Date(task.start_date)?.getTime() +
            task.time?.replace('day', '')?.replace('s', '') *
              24 *
              60 *
              60 *
              1000,
        ),
      );
    } else {
      setStartDate(addDaysToCurrentDate());
    }
  }, [data.body]);

  useEffect(() => {
    if (id !== uuid) {
      reset();
    }
  }, [id, uuid]);

  return (
    <>
      <Button
        className='mb-4'
        text='Add'
        Icon={PlusSmallIcon}
        onClick={() => {
          setId(uuid);
        }}
      />
      {/* <PlusSmallIcon
          className='h-5 text-primary cursor-pointer'
          onClick={() => {
            setId(uuid);
          }}
        /> */}

      <SidebarModal
        uuid={uuid}
        headerText={t('roadmap.create_new_task')}
        HeaderIcon={PlusSmallIcon}
        onClose={onClose}
      >
        <div className='flex flex-col gap-4 px-8 pb-2 lg:w-[30rem] md:w-[30rem]'>
          <Input
            label={t('roadmap.title')}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            value={title}
            required
          />
          <DatePicker
            date={startDate}
            setDate={(d) => {
              // if (endDate < d && endDate) {
              //   toast.error(t('roadmap.reversed_date_error'));
              //   return;
              // }
              setStartDate(d);
            }}
            label={t('roadmap.start_date')}
            required
          />
          <TextArea
            label={t('Description')}
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />

          <div className='flex flex-row justify-center mt-4'>
            <Button
              text={t('roadmap.add')}
              loading={loadingSubmit}
              onClick={() => {
                setData((prev) => ({
                  ...prev,
                  body: [
                    ...prev?.body,
                    {
                      id: generateTemporaryId(14),
                      name: title,
                      description,
                      time: '0 days',
                      start_date: format(startDate, 'yyyy-MM-dd'),
                      subtasks: [],
                    },
                  ].sort((a, b) => (a.start_date > b.start_date ? 1 : -1)),
                }));
                onClose();
              }}
            />
          </div>
        </div>
      </SidebarModal>
    </>
  );
};

export default AddTask;
