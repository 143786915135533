import React, { useState } from 'react';
import { Button } from '../../../../../components/forms/Buttons/Button';
import Input from '../../../../../components/forms/Inputs/Input';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TextArea from '../../../../../components/forms/Inputs/TextArea';
import { PlusIcon } from '@heroicons/react/24/outline';
import { postSection } from '../../../../../services/courses/sectionService';

const Add = ({ refreshData, course }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = (data) => {
    const body = { ...data };
    body.course = course;
    postSection(body)
      .then(() => refreshData())
      .catch(() => { })
      .finally(() => onClose());
  };

  return (
    <>
      <Button Icon={PlusIcon} onClick={() => setOpen(true)} />
      <CustomDialog isOpen={open} closeModal={() => onClose()}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 pt-4"
        >
          <div className="w-full">
            <Input
              errorMsg={errors.name}
              required
              label="title"
              {...register('name', {
                required: 'title is required field',
              })}
            />
            <TextArea
              errorMsg={errors.description?.message}
              required
              rows={7}
              label="description"
              {...register('description', {
                required: 'description is required field',
              })}
            />
            <Input
              errorMsg={errors.duration?.message}
              required
              label="duration"
              type="number"
              {...register('duration', {
                required: 'duration is required field',
                valueAsNumber: true,
                validate: (value) =>
                  value > 0 || 'Duration must be a positive number',
              })}
            />
          </div>
          <div className="flex flex-row justify-end gap-2">
            <Button
              color="secondary"
              text={t('buttons.cancel')}
              onClick={() => onClose()}
              type="button"
            />
            <Button color="success" text={t('buttons.create')} type="submit" />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Add;
