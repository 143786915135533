import React, { useEffect, useState } from 'react';
import CourseCard from './CourseCard';

const Student = ({ data, refreshData }) => {
  const [loading, setLoading] = useState(true);

  // Эмуляция загрузки данных (если данных нет или они не загружены, показываем скелетоны)
  useEffect(() => {
    if (data && data.length > 0) {
      setLoading(false); // Когда данные загружены, меняем состояние
    }
  }, [data]);

  // Скелетоны загрузки
  const renderSkeletons = () => {
    return (
      <div className='flex flex-row gap-2 flex-wrap'>
        {[...Array(2)].map((_, index) => (
          <div key={index} className='w-[200px] h-[200px] rounded-xl animate-pulse bg-gray-300' />
        ))}
      </div>
    );
  };

  return (
    <div className='flex flex-row gap-2 flex-wrap'>
      {loading ? (
        renderSkeletons() // Если данные еще загружаются, отображаем скелетоны
      ) : data?.length ? (
        data.map((d) => {
          return <CourseCard key={d.uuid} data={d} refreshData={refreshData} />;
        })
      ) : (
        <div className='w-full flex flex-row justify-center py-2 mt-4 text-gray-400 text-lg'>
          No content
        </div>
      )}
    </div>
  );
};

export default Student;
