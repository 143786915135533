import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import Checkbox from '../../../../components/forms/Checkbox/Checkbox';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
import Select from '../../../../components/forms/Select/Select';
import { useMediaQuery } from 'react-responsive';
import { Button } from '../../../../components/forms/Buttons/Button';
import { getLocalUniversies } from '../../../../services/universities/localUniversitiesService';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import { ScrollContext } from '../../../../utils/contexts/ScrollContext';
import UniversityCard from './UniversityCard';
import {
  AdjustmentsHorizontalIcon,
  InformationCircleIcon,
} from '@heroicons/react/24/solid';
import { SidebarContext } from '../../../../utils/contexts/SidebarContext';
import MobileSidebarModal from '../../../../components/modals/MobileSidebarModal/MobileSidebarModal';
import { t } from 'i18next';
import { debounce } from 'lodash';

const Local = ({ setTab, UNT }) => {
  const [firstSubject, setFirstSubject] = useState('');
  const [secondSubject, setSecondSubject] = useState('');
  const [isGrant, setIsGrant] = useState(true);
  const [grantCount, setGrantCount] = useState(0);
  const [paidCount, setPaidCount] = useState(0);
  const [UNTScore, setUNTScore] = useState(0);
  const [universities, setUniversities] = useState([]);
  const [isRequested, setIsRequested] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);
  const { scrollRef } = useContext(ScrollContext);

  const pointer = useRef();
  const isMobile = useMediaQuery({ query: `(max-width: 768px)` });
  const SUBJECTS = useMemo(
    () => [
      {
        label: t('universities.local.subjects.physics'),
        value: '6',
        code: '02',
      },
      {
        label: t('universities.local.subjects.biology'),
        value: '9',
        code: '04',
      },
      {
        label: t('universities.local.subjects.mathematics'),
        value: '10',
        code: '01',
      },
      {
        label: t('universities.local.subjects.chemistry'),
        value: '11',
        code: '03',
      },
      {
        label: t('universities.local.subjects.geography'),
        value: '12',
        code: '06',
      },
      {
        label: t('universities.local.subjects.world_history'),
        value: '17',
        code: '07',
      },
      {
        label: t('universities.local.subjects.kazakh_literature'),
        value: '19',
        code: '24',
      },
      {
        label: t('universities.local.subjects.law_basics'),
        value: '20',
        code: '53',
      },
      {
        label: t('universities.local.subjects.english_language'),
        value: '30',
        code: '05',
      },
      {
        label: t('universities.local.subjects.kazakh_language'),
        value: '59',
        code: '09',
      },
      {
        label: t('universities.local.subjects.russian_literature'),
        value: '169',
        code: '11',
      },
      {
        label: t('universities.local.subjects.computer_science'),
        value: '452',
        code: '08',
      },
    ],
    [],
  );

  const setUNT = useCallback((value) => {
    let len = 288;
    // if (isMobile) {
    //   len = 128;
    // }
    let mod = -8;
    if (value <= 70) {
      mod += 8 * (value / 70);
    } else {
      mod += 16 * (value / 140);
    }
    if (pointer.current) {
      pointer.current.style.left = Math.round((value / 140) * len + mod) + 'px';
    }
    setUNTScore(value);
  }, []);

  const getLocalsUniversityData = ({
    firstSubject,
    secondSubject,
    score,
    isGrant,
  }) => {
    setIsRequested(true);
    setIsLoading(true);
    getLocalUniversies({
      firstSubject,
      secondSubject,
      score,
      isGrant: true,
    })?.then((res) => {
      if (isGrant) {
        setUniversities(res?.data?.universities);
      }
      setGrantCount(res?.data?.universities?.length);
    });
    getLocalUniversies({
      firstSubject,
      secondSubject,
      score,
      isGrant: false,
    })
      ?.then((res) => {
        if (!isGrant) {
          setUniversities(res?.data?.universities);
        }
        setPaidCount(res?.data?.universities?.length);
      })
      ?.finally(() => setIsLoading(false));
  };

  useEffect(() => {
    if (UNT?.length) {
      const subjects = UNT[0]?.subjects.filter(
        (sub) => !['29', '27', '12'].includes(sub.subject_code),
      );
      if (subjects.length === 2) {
        setFirstSubject(
          SUBJECTS.find((sub) => sub.code === subjects[0]?.subject_code)?.value,
        );
        setSecondSubject(
          SUBJECTS.find((sub) => sub.code === subjects[1]?.subject_code)?.value,
        );
        setUNT(UNT[0]?.total_overall);
      }
    } else {
      setUNT(100);
    }
  }, [setUNT, UNT, SUBJECTS]);

  const onUNTScoreChange = useMemo(
    () =>
      debounce((e) => {
        setUNTScore(e.target.value);
        getLocalsUniversityData({
          firstSubject,
          secondSubject,
          score: e.target.value,
          isGrant,
        });
      }, 1500),
    [firstSubject, secondSubject, isGrant],
  );

  const FilterComponent = () => (
    <div className='flex flex-col w-60 shrink-0 sticky gap-4 md:border-2 md:shadow-lg rounded-lg p-2 '>
      <div className='flex flex-col gap-2 pb-3'>
        {!isMobile && <p className='font-medium'>{t('universities.filter')}</p>}
        <div className='flex flex-col w-40 md:w-80 gap-2'>
          <p>{t('universities.local.my_selected_subjects')}</p>
          <div className='flex flex-col w-40 gap-2'>
            <Select
              value={firstSubject}
              onChange={(e) => {
                setFirstSubject(e.value);
                getLocalsUniversityData({
                  firstSubject: e.value,
                  secondSubject,
                  score: UNTScore,
                  isGrant,
                });
              }}
              className='w-40'
              options={SUBJECTS}
            />
            <Select
              value={secondSubject}
              onChange={(e) => {
                setSecondSubject(e.value);
                getLocalsUniversityData({
                  firstSubject,
                  secondSubject: e.value,
                  score: UNTScore,
                  isGrant,
                });
              }}
              className='w-40 '
              options={SUBJECTS}
            />
          </div>
          <div className='border-b w-40 my-4' />
          <p>{t('universities.local.my_unt_points')}</p>
          <div className='flex static flex-row w-40'>
            <input
              className='border rounded-lg p-2 w-40'
              type='number'
              min='0'
              max='140'
              defaultValue={UNTScore}
              onChange={onUNTScoreChange}
            />
          </div>
          <div className='border-b w-40 my-4' />
          <Checkbox
            label={t('universities.local.grant')}
            onChange={() => {
              setIsGrant(true);
              getLocalsUniversityData({
                firstSubject,
                secondSubject,
                score: UNTScore,
                isGrant: true,
              });
            }}
            checked={isGrant}
          />
          <Checkbox
            label={t('universities.local.paid')}
            onChange={() => {
              setIsGrant(false);
              getLocalsUniversityData({
                firstSubject,
                secondSubject,
                score: UNTScore,
                isGrant: false,
              });
            }}
            checked={!isGrant}
          />
        </div>
      </div>
    </div>
  );
  return (
    <div className='flex md:flex-row flex-col w-full h-full'>
      <div
        className='flex flex-row justify-between mx-auto gap-2
       md:flex-col md:justify-start relative md:mx-0'
      >
        <div className='lg:border-2 lg:rounded-lg p-2 lg:shadow-md lg:w-60'>
          <p className='font-semibold mb-2 block'>
            {t('universities.university_type')}
          </p>
          <div className='lg:flex-col flex flex-row gap-2 flex-wrap justify-center'>
            <Button
              className='w-36 h-8 md:h-8 lg:h-8'
              onClick={() => setTab('foreign')}
              text={t('universities.foreign')}
            />
            <Button
              className='w-36 h-8 md:h-8 lg:h-8'
              color={'primary'}
              text={t('universities.local.local')}
            />
            <Button
              className='w-36 h-8 md:h-8 lg:h-8'
              onClick={() => setTab('special')}
              text={t('universities.special_local.special_local')}
            />
          </div>
        </div>

        {isRequested && (
          <>
            {isMobile ? (
              <div>
                <MobileSidebarModal
                  headerText={t('universities.filter')}
                  uuid={uuid}
                  onClose={() => setId('')}
                >
                  <FilterComponent />
                </MobileSidebarModal>
              </div>
            ) : (
              <FilterComponent />
            )}
          </>
        )}
      </div>
      {isMobile && isRequested && (
        <div className='z-10 w-full fixed bottom-8 flex flex-row justify-center'>
          <div
            className='rounded-3xl bg-primary text-white px-4 py-2
          flex flex-row items-center gap-1'
            onClick={() => setId(uuid)}
          >
            <p>{t('universities.filter')}</p>
            <AdjustmentsHorizontalIcon className='h-4' />
          </div>
        </div>
      )}
      {!isRequested ? (
        <div className='flex flex-col items-center row w-full h-full'>
          <div className='flex flex-col w-full items-center md:w-88 pt-6 gap-2'>
            <p className='text-center text-blue-600 mb-20 text-3xl font-bold'>
              {t('universities.local.title')}
            </p>
            <div>
              <p className='w-80'>
                {t('universities.local.my_selected_subjects')}
              </p>
              <div className='flex flex-col md:flex-row lg:w-96 gap-2 md:justify-between py-4'>
                <Select
                  placeholder={t('universities.local.first_subject')}
                  value={firstSubject}
                  onChange={(e) => setFirstSubject(e.value)}
                  className='w-80 h-10'
                  options={SUBJECTS}
                />
                <Select
                  placeholder={t('universities.local.second_subject')}
                  value={secondSubject}
                  onChange={(e) => setSecondSubject(e.value)}
                  className='w-80 max-h-8'
                  options={SUBJECTS}
                />
              </div>
              <p className='w-80'>{t('universities.local.my_unt_points')}</p>
              <div className='flex static flex-row w-80'>
                <span
                  style={{ position: 'relative' }}
                  ref={pointer}
                  className={`top-0 absolute select-none bg-primary text-white 
                h-8 w-8 text-sm flex flex-col items-center justify-center rounded-full`}
                >
                  {UNTScore}
                </span>
              </div>
              <div className=' flex flex-col gap-2 '>
                <input
                  onChange={(e) => setUNT(e.target.value)}
                  className='w-80'
                  type='range'
                  min='0'
                  max='140'
                  value={UNTScore}
                />
                <Button
                  disabled={(!firstSubject || !secondSubject) ?? !UNTScore}
                  text={t('universities.local.check')}
                  className='w-80 mb-20'
                  color='primary'
                  onClick={() => {
                    getLocalsUniversityData({
                      firstSubject,
                      secondSubject,
                      score: UNTScore,
                      isGrant,
                    });
                  }}
                />
              </div>
            </div>

            <div className='flex border-2 solid border-red-400 w-96 rounded-3xl p-4 gap-2'>
              <InformationCircleIcon className='w-40 text-red-400' />
              <p>{t('universities.local.info')}</p>
            </div>
          </div>
        </div>
      ) : isLoading ? (
        <SimpleLoader className='h-5' />
      ) : !universities?.length ? (
        <div
          className='flex flex-row justify-center w-full h-min py-8 mt-4 md:mx-8 text-gray-400 text-lg
        border rounded-lg'
        >
          {t('universities.local.no_content')}
        </div>
      ) : (
        <div className='px-4 flex flex-col gap-4 md:mt-0 mt-4'>
          <span className='font-bold text-2xl text-blue-600'>
            {t('universities.local.universities_list')}
          </span>
          <div className='flex flex-row gap-1 items-center'>
            <span className='font-black text-lg text-primary'>
              {t('universities.local.grant')}
            </span>
            <span className='px-3 py-1 text-sm bg-primary rounded-full mr-2 text-white'>
              {grantCount}
            </span>
            <span className='font-black text-lg text-violet-600'>
              {t('universities.local.paid')}
            </span>
            <span className='px-3 py-1 text-sm bg-violet-600 text-white rounded-full'>
              {paidCount}
            </span>
          </div>
          {universities?.map((university, index) => {
            return (
              <UniversityCard
                key={index}
                university={university}
                params={{
                  firstSubject,
                  secondSubject,
                  score: UNTScore,
                  isGrant,
                }}
              />
            );
          })}
        </div>
      )}
      <ArrowUpCircleIcon
        className={`h-10 bottom-10 right-3 absolute z-20 text-primary cursor-pointer animate-[bounce_1s_ease-in-out_2.5] `}
        onClick={() => {
          scrollRef?.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
            block: 'start',
          });
        }}
      />
    </div>
  );
};

export default Local;
