import React, { useState, useCallback, useEffect, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon, CheckIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import { getLessons, getCompletedLessons, completeLesson } from '../../../../services/courses/lessonService';
import { getSections } from '../../../../services/courses/sectionService';
import { postUserProgress } from '../../../../services/courses/userProgressService';
import Content from '../../Lesson/Student/Contents/Content';
import CircleProgressBar from '../../../../components/charts/CircleProgressBar';
import Clock from '../../../../components/Icons/Clock';
import avatarPlaceholder from '../../../../assets/images/avatar_placeholder.jpg';
import CheckMark from '../../../../components/Icons/CheckMark';
import { UserContext } from '../../../../utils/contexts/UserContext';
import { Button } from '../../../../components/forms/Buttons/Button';
import { Link, useNavigate } from 'react-router-dom';


const Student = ({ data: sections, course, refreshCourse }) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [expandedSections, setExpandedSections] = useState(new Set());
  const [lessons, setLessons] = useState({});
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [lessonContent, setLessonContent] = useState([]);
  const [completedLessons, setCompletedLessons] = useState(new Set());
  const [loading, setLoading] = useState(false);
  const endOfContentRef = useRef(null);
  const [nextLesson, setNextLesson] = useState();


  useEffect(() => {
    console.log('Sections:', sections);
    console.log('Course:', course);
    console.log('Current User:', user);
  }, [sections, course, user]);

 
  useEffect(() => {
    const fetchCompletedLessons = async () => {
      if (!user || !user.uuid) return;
      setLoading(true);
      try {
        const completed = await getCompletedLessons(user.uuid);
        setCompletedLessons(new Set(completed));
      } catch (error) {
        console.error('Ошибка при получении завершённых уроков:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCompletedLessons();
  }, [user]);

  const totalLessons = sections.reduce((acc, section) => acc + (lessons[section.uuid]?.length || 0), 0);
  const completedLessonsCount = completedLessons.size;
  const courseProgress = totalLessons > 0 ? (completedLessonsCount / totalLessons) * 100 : 0;


  const getLessonsData = useCallback(async (sectionUuid) => {
    if (lessons[sectionUuid]) return;

    console.log(`Fetching lessons for section: ${sectionUuid}`);
    setLoading(true);
    try {
      const res = await getLessons({ section: sectionUuid });
      console.log(`Lessons fetched for section ${sectionUuid}:`, res.results);
      setLessons((prev) => ({
        ...prev,
        [sectionUuid]: res.results || [],
      }));
    } catch (error) {
      console.error(`Ошибка при загрузке уроков для секции ${sectionUuid}:`, error);
      setLessons((prev) => ({
        ...prev,
        [sectionUuid]: [],
      }));
    } finally {
      setLoading(false);
    }
  }, [lessons]);


  const getLessonContent = useCallback(async (lessonUuid) => {
    if (!lessonUuid) {
      console.error('lessonUuid is undefined or null.');
      return;
    }
    if (!course.uuid) {
      console.error('course.uuid is undefined or null.');
      return;
    }
    if (!user || !user.uuid) {
      console.error('User is undefined or does not have a uuid.');
      return;
    }

    console.log(`Fetching content for lesson: ${lessonUuid}`);
    setLoading(true);
    try {
      const res = await getLessons({ uuid: lessonUuid });
      console.log(`Lesson content fetched for ${lessonUuid}:`, res);
      setSelectedLesson(res);
      setLessonContent(res?.content || []);
    } catch (error) {
      console.error(`Ошибка при загрузке контента для урока ${lessonUuid}:`, error);
      setSelectedLesson(null);
      setLessonContent([]);
    } finally {
      setLoading(false);
    }
  }, [course.uuid, user]);


  const toggleSection = (sectionUuid) => {
    setExpandedSections((prev) => {
      const updated = new Set(prev);
      if (updated.has(sectionUuid)) {
        updated.delete(sectionUuid);
      } else {
        updated.add(sectionUuid);
        getLessonsData(sectionUuid);
      }
      return updated;
    });
  };


  const handleLessonClick = (lesson) => {
    if (!lesson || !lesson.uuid) {
      console.error('Invalid lesson:', lesson);
      return;
    }
    getLessonContent(lesson.uuid);
  };

  const allLessons = sections.flatMap(section => lessons[section.uuid] || []);

  const handlePrevLesson = () => {
    if (!selectedLesson) return;
    const currentIndex = allLessons.findIndex(l => l.uuid === selectedLesson.uuid);
    if (currentIndex > 0) {
      const prevLesson = allLessons[currentIndex - 1];
      getLessonContent(prevLesson.uuid);
    }
  };

  const handleNextLesson = () => {
    if (!selectedLesson) return;
    const currentIndex = allLessons.findIndex(l => l.uuid === selectedLesson.uuid);
    if (currentIndex < allLessons.length - 1) {
      const next = allLessons[currentIndex + 1];
      getLessonContent(next.uuid);
    }
  };

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours > 0) {
      return remainingMinutes > 0
        ? `${hours} ч ${remainingMinutes} мин`
        : `${hours} ч`;
    }
    return `${remainingMinutes} мин`;
  };

  useEffect(() => {
    if (!selectedLesson) return;
    if (completedLessons.has(selectedLesson.uuid)) return;

    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          console.log(`Пользователь достиг конца урока ${selectedLesson.uuid}`);
          completeLesson({
            lessonUuid: selectedLesson.uuid,
            userUuid: user.uuid,
          })
            .then((response) => {
              console.log(`Урок ${selectedLesson.uuid} успешно отмечен как завершённый:`, response);
              setCompletedLessons((prev) => new Set(prev).add(selectedLesson.uuid));
              postUserProgress({ lesson: selectedLesson.uuid, status: 2, user: user.uuid });
            })
            .catch((error) => {
              console.error(`Ошибка при отметке урока ${selectedLesson.uuid} как завершенного:`, error);
              alert(`Ошибка при завершении урока: ${error.non_field_errors?.join(', ') || 'Произошла ошибка.'}`);
            });
          observer.disconnect();
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      }
    );

    if (endOfContentRef.current) {
      observer.observe(endOfContentRef.current);
    }

    return () => {
      if (endOfContentRef.current) {
        observer.unobserve(endOfContentRef.current);
      }
      observer.disconnect();
    };
  }, [selectedLesson, completedLessons, user.uuid]);


  useEffect(() => {
    if (!selectedLesson) return;

    const determineNextLesson = async () => {
      const currentIndex = allLessons.findIndex(l => l.uuid === selectedLesson.uuid);
      if (currentIndex !== -1 && currentIndex < allLessons.length - 1) {
        setNextLesson(allLessons[currentIndex + 1]);
      } else {
        const currentSectionIndex = sections.findIndex(section => section.uuid === selectedLesson.sectionUuid);
        if (currentSectionIndex !== -1 && currentSectionIndex < sections.length - 1) {
          const nextSection = sections[currentSectionIndex + 1];
          if (lessons[nextSection.uuid] && lessons[nextSection.uuid].length > 0) {
            setNextLesson(lessons[nextSection.uuid][0]);
          }
        }
      }
    };

    determineNextLesson();
  }, [selectedLesson, allLessons, sections, lessons]);

  return (
    <div className="flex flex-col min-h-screen">
      <div className="text-white rounded-xl py-4 px-8 mt-4 bg-[#2F80ED] flex items-center">
        {/* Кнопка слева 
        <Button
          className="flex items-center mr-4"
          text={t('back')}
          color="secondary-solid"
          Icon={ChevronLeftIcon}
          onClick={() => {
            setSelectedLesson(null);
            setLessonContent([]);
          }}
        />*/}
        <h1 className="text-2xl font-bold flex-1 text-center">{course.name}</h1>
      </div>
      <div className="flex flex-col lg:flex-row flex-1">
        {selectedLesson ? (
          <div className="w-full h-full justify-center">
            <div className="mt-6 px-8">
              {Array.isArray(lessonContent) && lessonContent.length > 0 ? (
                lessonContent.map((content, index) => (
                  <Content key={index} data={content} />
                ))
              ) : (
                <p className="text-gray-500 italic">{t('No content available for this lesson.')}</p>
              )}
              <div ref={endOfContentRef} style={{ height: '1px' }}></div>
              <div className="flex justify-between mt-8">
                <button
                  onClick={handlePrevLesson}
                  disabled={allLessons.findIndex(l => l.uuid === selectedLesson.uuid) === 0}
                  className={`px-4 py-2 rounded ${allLessons.findIndex(l => l.uuid === selectedLesson.uuid) === 0
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-blue-600 text-white'
                    }`}
                >
                  {t('previous')}
                </button>
                <button
                  onClick={handleNextLesson}
                  disabled={allLessons.findIndex(l => l.uuid === selectedLesson.uuid) === (allLessons.length - 1)}
                  className={`px-4 py-2 rounded ${allLessons.findIndex(l => l.uuid === selectedLesson.uuid) === (allLessons.length - 1)
                    ? 'bg-gray-300 cursor-not-allowed'
                    : 'bg-blue-600 text-white'
                    }`}
                >
                  {t('buttons.next')}
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center w-full">
            <div className="flex flex-row items-center py-3">
              <div className="flex flex-col">
                <p className="text-lg text-gray-700">
                  <span className="font-bold">{t('Author')}:</span>
                  {course?.authors?.[0]?.author?.first_name || course?.authors?.[0]?.author?.last_name ? (
                    <span className="ml-1">{`${course?.authors?.[0]?.author?.first_name || ''} ${course?.authors?.[0]?.author?.last_name || ''}`.trim()}</span>
                  ) : (
                    <span className="ml-1">{t('No Author Information')}</span>
                  )}
                </p>
              </div>
              <div className="shrink-0 ml-4">
                <img
                  src={
                    course?.authors?.[0]?.author?.profile_picture || avatarPlaceholder
                  }
                  alt="avatar"
                  className="md:w-12 md:h-12 lg:w-12 lg:h-12 w-10 h-10 rounded-full object-cover"
                />
              </div>
            </div>

            <ul className="text-gray-700 flex flex-col justify-center space-y-2 text-xl mt-6">
              <p className='text-left text-gray-700 md:font-bold'>{t('You Will Learn How To')}</p>
              {course?.key_facts?.length > 0 ? (
                course.key_facts.map((fact, index) => (
                  <li key={index} className="flex items-center">
                    <CheckMark className="text-green-500 w-6 h-6 flex-shrink-0 mr-6" />
                    {fact}
                    <span className="leading-tight"></span>
                  </li>
                ))
              ) : (
                <p className="text-gray-500 italic">{t('No key facts available')}</p>
              )}
            </ul>
            <p className="py-6 text-left text-xl text-gray-700 md: font-bold">
              {t('About Course')}
              <p className='text-gray-700 text-lg md: font-normal py-2'>
                {course?.description || t('No course description available')}
              </p>
            </p>
          </div>
        )}

        {/* Сайдбар */}
        <div className="lg:w-1/3 bg-white shadow-md border border-gray-200 h-full sticky top-0 mt-10 rounded-3xl overflow-hidden">
          <div>
            <div className="flex justify-between items-center p-4 border-b border-gray-200">
              <h2 className="text-lg font-bold">{t('Материалы курса')}</h2>
              <div className="flex items-center text-green-600 text-sm">
                <Clock className="w-5 h-5 mr-1" />
                {formatDuration(course?.duration)}
              </div>
            </div>

            {sections?.map((section) => (
              <div key={section.uuid}>
                <div
                  className={`flex items-center justify-between px-4 py-3 cursor-pointer border-b border-gray-200 ${expandedSections.has(section.uuid) ? 'bg-blue-50 font-bold' : 'hover:bg-gray-100'
                    }`}
                  onClick={() => toggleSection(section.uuid)}
                >
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                      <CircleProgressBar progress={(section.section_status * 100).toFixed()} />
                      <span>{section.name}</span>
                    </div>
                    <div className="text-gray-500 text-xs flex items-center mt-1">
                      <Clock className="w-4 h-4 mr-1" />
                      {formatDuration(section.duration)}
                    </div>
                  </div>
                  <ChevronDownIcon
                    className={`h-5 transform transition-transform duration-300 ${expandedSections.has(section.uuid) ? 'rotate-180' : ''
                      }`}
                  />
                </div>

                {expandedSections.has(section.uuid) && (
                  <div>
                    {(lessons[section.uuid] || []).map((lesson) => (
                      <div
                        key={lesson.uuid}
                        className={`border-b border-gray-200 cursor-pointer ${selectedLesson?.uuid === lesson.uuid ? 'bg-blue-100 font-bold' : 'hover:bg-gray-100'
                          }`}
                        onClick={() => handleLessonClick(lesson)}
                      >
                        <div className="flex items-center gap-2 px-4 py-2">
                          <span className="flex-grow">{lesson.name}</span>
                          {completedLessons.has(lesson.uuid) && (
                            <CheckIcon className="w-5 h-5 text-green-500" title={t('completed')} />
                          )}
                        </div>
                        <div className="text-gray-500 text-xs flex items-center ml-3 mt-1">
                          <Clock className="w-4 h-4 ml-1 -translate-y-1 mt-2" />
                          <span className="ml-2">{formatDuration(lesson.duration)}</span>
                          {completedLessons.has(lesson.uuid) && (
                            <span className="ml-2 text-green-500">{t('completed')}</span>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>

      </div>
    </div>
  );
};

export default Student;
