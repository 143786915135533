import React, { useState, useContext } from 'react';
import ViewTask from '../CreateRoadmap/EditPart/BREAD/ViewTask';
import { patchRoadmapSubtask } from '../../../services/roadmapTask/roadmapSubtaskService';
import Checkbox from '../../../components/forms/Checkbox/Checkbox';
import { format } from 'date-fns';
import { UserContext } from '../../../utils/contexts/UserContext';
import {
  CalendarIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationCircleIcon,
  UserIcon,
} from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';

const Table = ({ tasks, getTasksData, roadmapCreatorUUID }) => {
  const { user } = useContext(UserContext);
  const [isOpen, setIsOpen] = useState(
    new Map(tasks?.map((d) => [d?.uuid, false])),
  );
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const today = new Date();
  return (
    <div className='border rounded-2xl overflow-hidden'>
      <table className='w-full lg:text-lg text-sm overflow-hidden'>
        <thead>
          <th className='w-fit p-2 md:px-4'>Status</th>
          <th className='text-left w-1/3 p-2 px-4'>Title</th>
          {!isMobile && <th className='w-fit p-2 px-4'>Start date</th>}
          {!isMobile && <th className='w-fit p-2 px-4'>Deadline</th>}
          <th className='w-full p-2 px-4'>Progress</th>
          <th className='min-w-[5] p-2 px-4'></th>
        </thead>
        <tbody>
          {tasks.map((task) => {
            const isStudentCreated = task.assignor?.uuid === task.student?.uuid;
            const canSeeIcon =
              user?.uuid === roadmapCreatorUUID ||
              user?.uuid === task.student?.uuid;

            return (
              <React.Fragment key={task.uuid}>
                <tr
                  key={task.uuid}
                  className={`${
                    task?.deadline &&
                    Date(task?.deadline) < today &&
                    !(task.children_count / task.done_count == 1)
                      ? 'bg-red-100'
                      : 'odd:bg-slate-100'
                  } `}
                >
                  <td className='flex flex-row items-center justify-center p-2 md:px-4 gap-1'>
                    {isOpen.get(task?.uuid) ? (
                      <ChevronUpIcon
                        className='lg:h-4 h-3 cursor-pointer'
                        onClick={() => {
                          setIsOpen((prev) => {
                            let temp = new Map(prev);
                            temp.set(task?.uuid, false);
                            return temp;
                          });
                        }}
                      />
                    ) : (
                      <ChevronDownIcon
                        className='lg:h-4 h-3 cursor-pointer'
                        onClick={() => {
                          setIsOpen((prev) => {
                            let temp = new Map(prev);
                            temp.set(task?.uuid, true);
                            return temp;
                          });
                        }}
                      />
                    )}
                    {task.deadline &&
                    new Date(task.deadline) < today &&
                    !(task.children_count / task.done_count == 1) ? (
                      <ExclamationCircleIcon
                        className='lg:h-5 h-4 text-error'
                        title='Task is expired'
                      />
                    ) : (
                      <CalendarIcon className='lg:h-5 h-4 text-success' />
                    )}
                  </td>
                  <td className='p-2 lg:px-4'>
                    <div className='flex items-center gap-2'>
                      {task.name}
                      {isStudentCreated && canSeeIcon && (
                        <div className='relative group'>
                          <UserIcon
                            className='h-4 text-primary cursor-pointer'
                            title='Student-created task'
                          />
                          <div className='absolute top-1/2 left-full ml-2 -translate-y-1/2 p-1 bg-gray-700 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity z-50'>
                            Student Craeted task
                          </div>
                        </div>
                      )}
                      {/* <Edit
                  datum={task}
                  // domains={domains}
                  // refreshData={refreshData}
                  className='hover:text-primary px-2 py-1.5 h-full truncate cursor-pointer'
                /> */}
                    </div>
                  </td>
                  <td className='p-2 lg:hidden text-xs'>
                    {task.done ? (
                      <span className='text-success'>Done</span>
                    ) : (
                      <span className='text-primary'>In progress</span>
                    )}
                  </td>

                  {!isMobile && (
                    <>
                      <td className=' items-center p-2 px-4'>
                        {task.start_date && (
                          <div className='p-1 rounded bg-gray-200 xl:w-32 w-28 text-center xl:text-base text-sm'>
                            {format(new Date(task.start_date), 'MMM d, yyyy')}
                          </div>
                        )}
                      </td>
                      <td className=' items-center p-2 px-4'>
                        {task.deadline && (
                          <div className='p-1 rounded bg-gray-200 xl:w-32 w-28 text-center xl:text-base text-sm'>
                            {format(new Date(task.deadline), 'MMM d, yyyy')}
                          </div>
                        )}
                      </td>
                      <td className='p-2 px-4 '>
                        {task?.children_count ? (
                          <div className='px-2 text-center'>
                            <div className='text-xs text-gray-600'>{`${task?.done_count}/${task?.children_count}`}</div>
                            <div className='relative h-2 rounded-md bg-sheet-gray'>
                              <div
                                className='h-2 rounded-md bg-primary absolute'
                                style={{
                                  width: `${
                                    (task?.done_count / task?.children_count) *
                                    100
                                  }%`,
                                }}
                              ></div>
                            </div>
                          </div>
                        ) : undefined}
                      </td>
                    </>
                  )}
                  <td className='p-2 text-xs'>
                    <ViewTask task={task} />
                  </td>
                </tr>
                {isOpen.get(task?.uuid) && (
                  <>
                    {isMobile && (
                      <tr
                        className={`${
                          task?.deadline && Date(task?.deadline) < today
                            ? 'bg-red-100'
                            : 'odd:bg-slate-100'
                        } `}
                      >
                        <td colSpan={2} className=''>
                          {task?.children_count ? (
                            <div className='px-2 flex flex-col'>
                              <span className='text-[0.63rem] text-left text-gray-600'>
                                Progress
                              </span>
                              <span className='text-xs text-center text-gray-600'>{`${task?.done_count}/${task?.children_count}`}</span>
                              <div className='relative h-2 rounded-md bg-sheet-gray'>
                                <div
                                  className='h-2 rounded-md bg-primary absolute'
                                  style={{
                                    width: `${
                                      (task?.done_count /
                                        task?.children_count) *
                                      100
                                    }%`,
                                  }}
                                ></div>
                              </div>
                            </div>
                          ) : undefined}
                        </td>

                        <td
                          className='flex sm:flex-row flex-col gap-2 justify-center items-center p-2 lg:px-4 px-0
                    lg:text-base text-sm'
                        >
                          {task.start_date && (
                            <div>
                              <span className='text-xs lg:hidden self-start text-secondary'>
                                Start date
                              </span>
                              <div className='lg:text-base text-xs p-1 rounded bg-gray-200 lg:w-28 w-20 text-center'>
                                {format(
                                  new Date(task.start_date),
                                  'MMM d, yyyy',
                                )}
                              </div>
                            </div>
                          )}
                          {task.deadline && (
                            <div>
                              <span className='text-xs lg:hidden self-start text-secondary'>
                                Deadline
                              </span>
                              <div className='lg:text-base text-xs p-1 rounded bg-gray-200 lg:w-28 w-20 text-center'>
                                {format(new Date(task.deadline), 'MMM d, yyyy')}
                              </div>
                            </div>
                          )}
                        </td>
                      </tr>
                    )}
                    {task?.children?.map((c) => (
                      <tr
                        className={`${
                          new Date(c.deadline) < today && !c.done
                            ? 'bg-red-100'
                            : 'odd:bg-slate-100'
                        }`}
                        key={c?.uuid}
                      >
                        <td className=' flex-row items-center justify-center p-2 px-4 gap-1 lg:flex hidden'>
                          <div className='flex flex-row items-center gap-1 justify-center'>
                            <CalendarIcon className='h-4 invisible' />
                            {new Date(c.deadline) < today && !c.done ? (
                              <ExclamationCircleIcon
                                className='h-5 text-error'
                                title='Task is expired'
                              />
                            ) : (
                              <CalendarIcon className='h-5 text-primary' />
                            )}
                          </div>
                        </td>
                        <td className='lg:hidden'>
                          <div className='flex flex-row justify-center items-center'>
                            <Checkbox
                              checked={c?.done}
                              onChange={() => {
                                const body = new FormData();
                                body.append('done', !c?.done);
                                patchRoadmapSubtask(c?.uuid, body)
                                  .then(() => {
                                    getTasksData();
                                  })
                                  .catch(() => {});
                              }}
                              disabled={task?.status === 'finished'}
                            />
                          </div>
                        </td>
                        <td className='p-2 lg:px-4 lg:text-base sm:text-sm text-xs'>
                          {c.name}
                        </td>

                        {isMobile ? (
                          <>
                            <td
                              className='flex sm:flex-row flex-col gap-2 justify-center items-center p-2 lg:px-4 px-0
                    lg:text-base text-sm'
                            >
                              {c.start_date && (
                                <div>
                                  <span className='text-xs lg:hidden self-start text-secondary'>
                                    Start date
                                  </span>
                                  <div className='lg:text-base text-xs p-1 rounded bg-gray-200 lg:w-28 w-20 text-center'>
                                    {format(
                                      new Date(c.start_date),
                                      'MMM d, yyyy',
                                    )}
                                  </div>
                                </div>
                              )}
                              {c.deadline && (
                                <div>
                                  <span className='text-xs lg:hidden self-start text-secondary'>
                                    Deadline
                                  </span>
                                  <div className='lg:text-base text-xs p-1 rounded bg-gray-200 lg:w-28 w-20 text-center'>
                                    {format(
                                      new Date(c.deadline),
                                      'MMM d, yyyy',
                                    )}
                                  </div>
                                </div>
                              )}
                            </td>
                          </>
                        ) : (
                          <>
                            {' '}
                            <td className=' items-center p-2 px-4'>
                              {c.start_date && (
                                <div className='p-1 rounded bg-gray-200 xl:w-32 w-28 text-center xl:text-base text-sm'>
                                  {format(
                                    new Date(c.start_date),
                                    'MMM d, yyyy',
                                  )}
                                </div>
                              )}
                            </td>
                            <td className=' items-center p-2 px-4'>
                              {c.deadline && (
                                <div className='p-1 rounded bg-gray-200 xl:w-32 w-28 text-center xl:text-base text-sm'>
                                  {format(new Date(c.deadline), 'MMM d, yyyy')}
                                </div>
                              )}
                            </td>
                            <td className=''>
                              <div className='flex flex-row justify-center'>
                                <Checkbox
                                  checked={c?.done}
                                  onChange={() => {
                                    const body = new FormData();
                                    body.append('done', !c?.done);
                                    patchRoadmapSubtask(c?.uuid, body)
                                      .then(() => {
                                        getTasksData();
                                      })
                                      .catch(() => {});
                                  }}
                                  disabled={task?.status === 'finished'}
                                />
                              </div>
                            </td>
                          </>
                        )}
                        <td className='p-2 text-xs'>
                          <ViewTask task={c} />
                        </td>
                      </tr>
                    ))}
                  </>
                )}

                {/* {task?.status !== 'finished' && isOpen.get(task?.uuid) && (
                <tr className='border-b last:border-b-0 hover:bg-gray-100 h-10'>
                  <td className='py-2 border-r px-2'>
                    <div className='flex flex-row items-center gap-1 justify-center'></div>
                  </td>
                  <td className='lg:text-bas text-sm border-r px-2'></td>
                  <td className='font-light lg:text-base text-sm text-center border-r px-2'></td>
                  <td
                    className={`lg:text-base text-sm pl-1 pr-2 w-48 border-r px-2`}
                  ></td>
                  <td className='text-center border-r px-2'></td>
                  <td className=''></td>
                </tr>
              )} */}
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
