import { useParams, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getAiToolByUuid } from '../../../services/aiTools/aiToolsService';
import Select from '../../../components/forms/Select/Select';

function ToolsStart() {
  const { uuid } = useParams(); // Get the tool ID from the URL params
  const navigate = useNavigate(); // For navigation
  const [tool, setTool] = useState(null); // Tool data
  const [loading, setLoading] = useState(true); // Loading indicator
  const [error, setError] = useState(null); // Error state
  const [inputValues, setInputValues] = useState({}); // State for dynamic input fields

  useEffect(() => {
    // Fetch tool details on component mount
    const fetchTool = async () => {
      try {
        const response = await getAiToolByUuid(uuid);

        if (!response || !Array.isArray(response.input_tools)) {
          throw new Error('Invalid or incomplete data received.');
        }

        // Initialize input fields based on tool configuration
        const initialInputs = response.input_tools.reduce((inputs, field) => {
          inputs[field.name] = ''; // Default each field to an empty string
          return inputs;
        }, {});

        setTool(response);
        setInputValues(initialInputs);
      } catch (err) {
        console.error('Error fetching tool details:', err.message);
        setError('Unable to load tool details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchTool();
  }, [uuid]);

  const handleInputChange = (fieldName, value) => {
    setInputValues((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const formatLessonPlan = () => {
    const prompt = tool?.prompt || 'Create a lesson plan:';
    const inputs = Object.entries(inputValues)
      .map(([key, value]) => `${key}: ${value || 'Not provided'}`)
      .join('\n');
    return `${prompt}\n\n${inputs}`;
  };

  const handleGenerateClick = () => {
    const lessonPlan = formatLessonPlan();
    navigate(`/aichat/${uuid}`, {
      state: { initialMessage: lessonPlan },
    });
  };

  if (loading) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
        <p className="text-gray-600 text-lg mt-4">Loading...</p>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen flex flex-col justify-center items-center bg-gray-50">
        <div className="bg-red-100 text-red-700 border border-red-500 rounded-lg p-6 shadow-lg max-w-sm text-center">
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (!tool) {
    return (
      <div className="min-h-screen flex justify-center items-center bg-gray-50">
        <p className="text-gray-700">Tool not found.</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 py-10 px-4">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl mx-auto">
        {/* Back Link at Top */}
        <div className="flex items-center space-x-2 mb-6">
          <span
            className="cursor-pointer text-blue-600 hover:underline"
            onClick={() => navigate('/aitools/')}
          >
            ← Back to Tools
          </span>
        </div>

        {/* Form Header */}
        <h1 className="text-3xl font-bold text-gray-800 mb-2">{tool.name}</h1>
        <p className="text-gray-600 text-lg mb-6">{tool.description}</p>

        <div className="space-y-6">
          {tool.input_tools.map((field, index) => (
            <div key={index}>
              <label className="block text-lg font-medium text-gray-700 mb-2">
                {field.name}
              </label>
              {Array.isArray(field.choices) && field.choices.length > 0 ? (
                <Select
                  options={field.choices.map((choice) => ({
                    label: choice.name,
                    value: choice.name,
                  }))}
                  placeholder={field.description || 'Select an option'}
                  value={inputValues[field.name]}
                  onChange={(e) => handleInputChange(field.name, e?.value)}
                />
              ) : (
                <textarea
                  className="w-full border rounded-lg shadow-sm p-3 bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                  placeholder={field.description || 'Enter your input here'}
                  rows="4"
                  value={inputValues[field.name]}
                  onChange={(e) => handleInputChange(field.name, e.target.value)}
                ></textarea>
              )}
            </div>
          ))}
        </div>

        <button
          className="w-full bg-blue-600 text-white font-bold py-3 rounded-full shadow-lg hover:bg-blue-700 mt-8 transition duration-200"
          onClick={handleGenerateClick}
        >
          Start
        </button>
      </div>
    </div>
  );
}

export default ToolsStart;
