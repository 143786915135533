import React from 'react';
//import Plyr from 'plyr-react';
import './plyr.css';

const VideoContent = ({ url }) => {
  // Функция для извлечения идентификатора видео из URL
  const getVideoId = (url) => {
    const regex = /(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = url.match(regex);
    return match ? match[1] : null;
  };

  const videoId = getVideoId(url);

  return (
    <div className="video-wrapper" style={{ aspectRatio: '16 / 9', margin: '0 auto' }}>
      {videoId ? (
        <iframe
          title="YouTube Video"
          width="100%"
          height="100%"
          src={`https://www.youtube.com/embed/${videoId}`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      ) : (
        <p>Invalid video URL</p>
      )}
    </div>
  );
};

export default VideoContent;
