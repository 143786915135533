import React from 'react';
import RichTextEditor from '../../../../../components/forms/Inputs/RichTextEditor';

const TextContent = ({ data }) => {
  return (
    <div className="px-6 py-4 bg-white shadow rounded-lg w-full max-w-4xl mx-auto">
      <h3 className="text-3xl font-bold text-gray-800 mb-4">{'Text Content'}</h3>
      <div className="ql-container ql-snow ql-editor text-2xl leading-relaxed">
        <RichTextEditor 
          readOnly 
          value={data.text} 
          className="ql-container ql-editor text-2xl leading-relaxed"
        />
      </div>
    </div>
  );
};

export default TextContent;
