import React, { useState } from 'react';
import { Button } from '../../../../../components/forms/Buttons/Button';
import Input from '../../../../../components/forms/Inputs/Input';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { PencilSquareIcon } from '@heroicons/react/24/outline';
import { patchLesson } from '../../../../../services/courses/lessonService';

const EditLesson = ({ refreshData, lesson }) => {
  const [t] = useTranslation();
  const [open, setOpen] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onClose = () => {
    reset();
    setOpen(false);
  };

  const onSubmit = (data) => {
    const body = { ...data, uuid: lesson.uuid };
    patchLesson(body)
      .then(() => refreshData())
      .catch(() => {})
      .finally(() => onClose());
  };

  return (
    <>
      <PencilSquareIcon
        className="text-primary h-9 cursor-pointer hover:bg-primary bg-blue-100 hover:text-white rounded-lg p-2"
        onClick={() => setOpen(true)}
      />
      <CustomDialog isOpen={open} closeModal={() => onClose()}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 pt-4"
        >
          <div className="w-full">
            <Input
              errorMsg={errors.name?.message}
              required
              label="title"
              defaultValue={lesson.name}
              {...register('name', {
                required: 'title is required field',
              })}
            />
            <Input
              errorMsg={errors.duration?.message}
              required
              label="duration"
              type="number"
              defaultValue={lesson.duration}
              {...register('duration', {
                required: 'duration is required field',
                valueAsNumber: true,
                validate: (value) =>
                  value > 0 || 'Duration must be a positive number',
              })}
            />
          </div>
          <div className="flex flex-row justify-end gap-2">
            <Button
              color="secondary"
              text={t('buttons.cancel')}
              onClick={() => onClose()}
              type="button"
            />
            <Button color="success" text={t('buttons.save')} type="submit" />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default EditLesson;
