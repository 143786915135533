/* eslint-disable no-unused-vars */
import { RadioGroup, Tab } from '@headlessui/react';
import { CheckCircleIcon, CheckIcon } from '@heroicons/react/24/outline';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { PreferredPairContext } from '../../../../utils/contexts/PreferredPairContext';
import {
  getSkillPoint,
  postPreferredPair,
} from '../../../../services/projects/skillsService';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

export default function PreferredPairs({ uuid, isOwnProfile }) {
  const [firstLevelSkills, setFirstLevelSkills] = useState([]);
  const [selectedPair, setSelectedPair] = useState(null);

  const { updatePair } = useContext(PreferredPairContext);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (uuid) {
      setLoading(true);
      getSkillPoint({ user_id: uuid, level: 1 })
        .then((res) => {
          setFirstLevelSkills(res.results);
          const allPairs = res?.results?.flatMap((skill) =>
            skill?.childs.map((p) => p),
          );
          const preferredPair = allPairs.find((p) => p?.is_prefered === true);
          setSelectedPair(preferredPair);
        })
        .finally(() => setLoading(false));
    }
    return () => {};
  }, [uuid]);

  return (
    <div className='flex flex-col px-4 py-2 gap-4'>
      {/* <h2 className='text-xl font-medium'>Preferred pair</h2> */}
      <p>
      {t('preferred_pairs.preferred_pairs_info')}
      </p>
      <div>
        <RadioGroup
          disabled={!isOwnProfile}
          className='flex flex-col gap-8'
          value={selectedPair}
          onChange={(e) => {
            if (isOwnProfile) {
              setSelectedPair(e);
              postPreferredPair(e?.uuid).then((res) => {
                updatePair(e?.uuid);
              });
            }
          }}
        >
          {loading ? (
            <SimpleLoader className='h-12 my-4' />
          ) : (
            firstLevelSkills?.map((skill) => (
              <div key={skill?.uuid}>
                <h2 className='text-lg mb-2'>{skill?.name}</h2>
                <div className='grid grid-cols-2 md:flex md:flex-wrap gap-4'>
                  {skill?.childs?.map((pair) => (
                    <RadioGroup.Option
                      key={pair?.uuid}
                      value={pair}
                      as={Fragment}
                    >
                      {({ active, checked }) => (
                        <div
                          className={`${
                            checked
                              ? `w-full md:w-48 flex flex-row gap-2 justify-between items-center px-2 py-1 rounded-md border ${
                                  isOwnProfile && 'cursor-pointer'
                                } border-blue-600 bg-blue-300 text-blue-600`
                              : `w-full md:w-48 flex flex-row gap-2 items-center px-2 py-1 rounded-md border ${
                                  isOwnProfile && 'cursor-pointer'
                                } bg-blue-100`
                          }`}
                        >
                          <div className=''>
                            <h2 className='text-base whitespace-nowrap'>
                              {pair?.name}
                            </h2>
                            <p className='text-xl font-semibold'>
                              {pair?.point}%
                            </p>
                          </div>
                          {checked ? (
                            <CheckCircleIcon className='ml-2 w-8' />
                          ) : (
                            <span className=' w-10'></span>
                          )}
                        </div>
                      )}
                    </RadioGroup.Option>
                  ))}
                </div>
              </div>
            ))
          )}
        </RadioGroup>
      </div>
    </div>
  );
}
