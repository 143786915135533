import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PlayIcon } from '@heroicons/react/24/solid';
import CircleProgressBar from '../../../../components/charts/CircleProgressBar';
import CheckMark from '../../../../components/Icons/CheckMark';
import Clock from '../../../../components/Icons/Clock';
import { useTranslation } from 'react-i18next';

function CourseCard({ data }) {
  const navigate = useNavigate();
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  useEffect(() => {
    const savedProgress = localStorage.getItem(`course-progress-${data?.uuid}`);
    if (savedProgress) {
      setProgress(parseInt(savedProgress, 10));
    }
  }, [data?.uuid]);

  const formatDuration = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    if (hours > 0) {
      return remainingMinutes > 0
        ? `${hours} ч ${remainingMinutes} мин`
        : `${hours} ч`;
    }
    return `${remainingMinutes} мин`;
  };

  return (
    <button
      className="w-[12.5rem] h-[12.5rem] rounded-xl cursor-pointer"
      onClick={() => navigate(`/courses/${data?.uuid}`)}
    >
      <div className="relative flex flex-col w-full h-full rounded-xl overflow-hidden shadow-lg transform transition duration-500 hover:scale-105 group">
        {/* Верхняя часть с градиентом */}
        <div className="flex flex-col justify-between p-4 h-[70%] rounded-t-xl bg-gradient-to-tr from-purple-700 to-blue-300">
          <div className="text-white text-left font-bold text-lg leading-tight">
            {data?.name}
          </div>
        </div>

        {/* Hover Overlay */}
        <div className="absolute inset-0 flex flex-col items-start justify-start bg-white opacity-0 group-hover:opacity-100 transition-opacity p-6 text-left rounded-xl">
          <div className="absolute top-3 left-3 flex items-center text-green-600 text-xs font-semibold">
            <Clock className="w-4 h-4 inline-block mr-1" /> 
            {formatDuration(data?.duration)}
          </div>

          {/* Список Key Facts */}
          <ul className="text-gray-700 flex flex-col justify-left space-y-1 text-xs mt-3">
            {data?.key_facts?.map((fact, index) => (
              <li key={index} className="flex items-left">
                <CheckMark className="text-green-500 w-4 h-4 flex-shrink-0 mr-1" />
                <span className="leading-tight">{fact}</span>
              </li>
            ))}
          </ul>
        </div>


        <div className="flex items-center justify-between p-4 bg-white w-full h-[30%] rounded-b-xl border-t">
          <div className="flex gap-4 text-center">
            <div>
              <div className="text-lg font-bold text-gray-800">
                {data?.sections}
              </div>
              <div className="text-xs text-gray-600">{t("courses.sections")}</div>
            </div>
            <div>
              <div className="text-lg font-bold text-gray-800">
                {data?.lessons}
              </div>
              <div className="text-xs text-gray-600">{t("courses.lessons")}</div>
            </div>
          </div>
          <div className="relative flex items-center justify-center w-16 h-16 opacity-100 group-hover:opacity-0 transition-opacity duration-300">
            <span
              className='pl-1 rounded-full cursor-pointer'
              onClick={() => {
                navigate(`/courses/${data?.uuid}`);
              }}
            >
              {data.course_progress ? (
                <CircleProgressBar
                  progress={(data.course_progress * 100).toFixed()}
                />
              ) : (
                <div className='relative w-11 h-11 bg-white rounded-full border-4 border-gray-300 hover:border-gray-400 focus:outline-none'>
                  <PlayIcon className='w-5 h-5 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />
                </div>
              )}
            </span>
          </div>
        </div>
      </div>
    </button>
  );
}

export default CourseCard;