import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './App.css';
import ProtectedRoute from './components/routing/ProtectedRoute/ProtectedRoute';
import BaseLayout from './components/layout/BaseLayout/BaseLayout';
import { useEffect } from 'react';
import { setDefaultLocale } from 'react-datepicker';
import { I18nextProvider } from 'react-i18next';
import i18n from './lang/i18n';
import Login from './pages/Login/Login';
import EmptyLogin from './pages/Login/EmptyLogin';
import useFindUser from './utils/hooks/useFindUser';
import { UserContext } from './utils/contexts/UserContext';
import Projects from './pages/Projects/Projects';
import Project from './pages/Projects/Project/Project';
import Submissions from './pages/Submissions/Submissions';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import SubmissionMain from './pages/Submissions/Submission/SubmissionMain';
import Assignments from './pages/Assignments/Assignments';
import Profile from './pages/Profile/Profile';
import { MyPreferredPairProvider } from './utils/contexts/PreferredPairContext';
import StudyPlans from './pages/StudyPlans/StudyPlans';
import StudyPlan from './pages/StudyPlans/StudyPlan';
import CommonRoute from './components/routing/CommonRoute/CommonRoute';
import Stats from './pages/Stats/Stats';
import LoginFromBilorg from './pages/Login/LoginFromBilorg';
import Tests from './pages/Tests/Tests/Tests';
import Test from './pages/Tests/Test/Test';
import Result from './pages/Tests/Result/Result';
import ResultDescriptions from './pages/Tests/ResultDescription/ResultDescriptions';
import Performance from './pages/Performance/Performance';
import Course from './pages/Courses/Course/Course';
import Courses from './pages/Courses/Courses/Courses';
import Lesson from './pages/Courses/Lesson/Lesson';
import CreateStudyPlan from './pages/StudyPlans/CreateStudyPlan';
import EditStudyPlan from './pages/StudyPlans/EditStudyPlan';
import CreateProject from './pages/Projects/CreateProject/CreateProject';
import Feed from './pages/Feed/Feed';
import PostPage from './pages/Feed/PostsPage';
import VideoConference from './pages/VideoConference/VideoConference';
import CreateVideoConference from './pages/VideoConference/CreateVideoConference/CreateVideoConference';
import JoinVideoConference from './pages/VideoConference/JoinVideoConference/JoinVideoConference';
import Universities from './pages/Universities/Universities';
import University from './pages/Universities/University/University';
import ResultDescriptionEdit from './pages/Tests/ResultDescription/ResultDescriptionEdit';
import Home from './pages/Home/Home';
import RoadmapMain from './pages/Roadmap/Main';
import CreateRoadmap from './pages/Roadmap/CreateRoadmap/CreateRoadmap';
import { Instructions } from './pages/Instructions/Instructions';
import CreateInstructionForm from './pages/Instructions/CreateInstructionForm/CreateInstructionForm';
import Instruction from './pages/Instructions/Instruction';
import EditRoadmap from './pages/Roadmap/EditRoadmap/EditRoadmap';
import AddTaskRoadmap from './pages/Roadmap/EditRoadmap/AddTaskRoadmap';
import AiTools from './pages/AiTools/AiTools';
import ToolsStart from './pages/AiTools/Tools/ToolsStart';
import ToolsChat from './pages/AiTools/Tools/ToolsChat';
import Training from './pages/Training/Training';
function App() {
  const { user, setUser, isLoading } = useFindUser();

  useEffect(() => {
    setDefaultLocale(localStorage.getItem('i18nextLng'));

    return () => {};
  }, []);

  return (
    <BrowserRouter>
      <I18nextProvider i18n={i18n}>
        <UserContext.Provider value={{ user, setUser, isLoading }}>
          <MyPreferredPairProvider value={{}}>
            <ToastContainer position='bottom-right' />

            <Routes>
              {/* Public part */}
              <Route path='/login' element={<Login />} />
              <Route path='/apalogin' element={<EmptyLogin />} />
              <Route path='/login-with-bilorg' element={<LoginFromBilorg />} />
              <Route
                path='/join-video-conference/:uuid'
                element={<JoinVideoConference />}
              />

              {/* Private part */}
              <Route
                element={
                  <ProtectedRoute
                    isAllowed={
                      !!user?.uuid && user?.roles?.length
                      // user?.roles?.some((role) => role?.name === 'STUDENT')
                    }
                    isLoading={isLoading}
                  />
                }
              >
                <Route
                  path='/'
                  element={<BaseLayout user={user} setUser={setUser} />}
                >
                  {/* Common paths */}
                  <Route path='' element={<CommonRoute user={user} />} />
                  <Route path='feed' element={<Feed />} />
                  <Route path='feed/:uuid' element={<PostPage />} />
                  {/* <Route path='dashboard' element={<Dashboard />} /> */}
                  <Route path='home' element={<Home />} />
                  <Route path='universities' element={<Universities />} />
                  <Route path='universities/:key' element={<University />} />
                  <Route path='instructions' element={<Instructions />} />
                  <Route path='instructions/:uuid' element={<Instruction />} />
                  <Route
                    path='/instructionCreate'
                    element={<CreateInstructionForm />}
                  />
                  <Route path='profile/:uuid' element={<Profile />} />
                  <Route path='study-plans' element={<StudyPlans />} />
                  <Route path='study-plans/:uuid' element={<StudyPlan />} />
                  <Route
                    path='study-plans/create'
                    element={<CreateStudyPlan />}
                  />
                  <Route
                    path='study-plans/edit/:uuid'
                    element={<EditStudyPlan />}
                  />
                  <Route path='tests' element={<Tests />} />
                  <Route path='tests/:uuid' element={<Test />} />
                  <Route path='results/:uuid' element={<Result />} />
                  <Route
                    path='tests/:uuid/descriptions'
                    element={<ResultDescriptions />}
                  />
                  <Route
                    path='tests/:uuid/description/:descriptionUuid/edit/'
                    element={<ResultDescriptionEdit />}
                  />
                  <Route path='projects' element={<Projects />} />
                  <Route path='projects/:uuid' element={<Project />} />
                  <Route path='roadmap' element={<RoadmapMain />} />
                  <Route path='courses' element={<Courses />} />
                  <Route path='courses/:uuid' element={<Course />} />
                  <Route
                    path='courses/:course/lesson/:uuid'
                    element={<Lesson />}
                  />
                  <Route path='projects/create' element={<CreateProject />} />
                  <Route
                    path='video-conference'
                    element={<VideoConference />}
                  />

                  {/* Studets' paths */}
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user?.uuid &&
                          user?.roles?.some((role) => role?.name === 'STUDENT')
                        }
                        isLoading={isLoading}
                      />
                    }
                  >
                    <Route
                      path='roadmap/addtask/:uuid'
                      element={<AddTaskRoadmap />}
                    />
                    <Route path='performance' element={<Performance />} />
                  </Route>

                  {/* Teachers' paths */}
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user?.uuid &&
                          (user?.roles?.some(
                            (role) => role?.name === 'PROJECTADMIN',
                          ) ||
                            user?.roles.some(
                              (role) => role.name === 'PROJECTREVIEWER',
                            ))
                        }
                        isLoading={isLoading}
                      />
                    }
                  >
                    <Route path='assignments' element={<Assignments />} />
                    <Route path='submissions' element={<Submissions />} />
                    <Route
                      path='submissions/:uuid'
                      element={<SubmissionMain />}
                    />
                    <Route path='stats' element={<Stats />} />
                    {/* <Route path='leaderboard' element={<Leaderboard />} /> */}

                    <Route
                      path='video-conference/create'
                      element={<CreateVideoConference />}
                    />
                    <Route path='roadmap/create' element={<CreateRoadmap />} />
                    <Route
                      path='roadmap/edit/:uuid'
                      element={<EditRoadmap />}
                    />
                  </Route>
                  <Route path='aitools' element={<AiTools />} />
                  <Route path="/aitools/:uuid" element={<ToolsStart />} />
                  <Route path="/aichat/:uuid" element={<ToolsChat />} />
                  <Route path="/training_course_test" element={<Training />} />

                  {/* Admin's paths */}
                  <Route
                    element={
                      <ProtectedRoute
                        isAllowed={
                          !!user?.uuid &&
                          user?.roles?.some((role) => role?.name === 'ADMIN')
                        }
                        isLoading={isLoading}
                      />
                    }
                  ></Route>
                </Route>
              </Route>
            </Routes>
          </MyPreferredPairProvider>
        </UserContext.Provider>
      </I18nextProvider>
    </BrowserRouter>
  );
}

export default App;
