import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
  useRef,
  createContext,
} from 'react';
import { MagnifyingGlassIcon, MapPinIcon } from '@heroicons/react/24/solid';
import {
  getUniversities,
  getUniversityCountries,
} from '../../../../services/universities/universityService';
import Checkbox from '../../../../components/forms/Checkbox/Checkbox';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
import Select from '../../../../components/forms/Select/Select';
import { useSearchParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { ArrowUpCircleIcon } from '@heroicons/react/24/outline';
import MobileSidebarModal from '../../../../components/modals/MobileSidebarModal/MobileSidebarModal';
import { AdjustmentsHorizontalIcon } from '@heroicons/react/24/outline';
import { SidebarContext } from '../../../../utils/contexts/SidebarContext';
import { Button } from '../../../../components/forms/Buttons/Button';
import {
  getSpecialLocalUniversities,
  getSpecialLocalUniversityInfo,
} from '../../../../services/universities/specialUniversitiesService';
import { t } from 'i18next';
import { getTranlated } from '../../helper';
import University from '../../University/University';
import Description from './Description';
const PAGE_SIZE = 12;
const BIG_SIZE = 32;

const SpecialLocal = ({ setTab }) => {
  const responsivePageSize = useMemo(
    () => (window.innerWidth < 2560 ? PAGE_SIZE : BIG_SIZE),
    [],
  );
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [loadingMoreData, setLoadingMoreData] = useState(false);
  const [top100, setTop100] = useState();
  const [top200, setTop200] = useState();
  const [top300, setTop300] = useState();
  const [fee10, setFee10] = useState(false);
  const [fee20, setFee20] = useState(false);
  const [fee30, setFee30] = useState(false);
  const [fee40, setFee40] = useState(false);
  const [funding, setFunding] = useState();
  const [country, setCountry] = useState();
  const [countries, setCountries] = useState([]);
  const [loadingCountries, setLoadingCountries] = useState(false);
  const [page, setPage] = useState(1);
  const [N, setN] = useState(0);
  const [search, setSearch] = useState();
  const { setId } = useContext(SidebarContext);
  const uuid = useMemo(() => crypto.randomUUID(), []);
  const scrollRef = useRef(null);
  const isLocal = true;

  const getData = useCallback(() => {
    setLoadingData(true);

    getSpecialLocalUniversities({
      isLocal: isLocal,
    })
      .then((res) => {
        setData(res?.data);
        setN(res?.totalCount);
        setPage(1);
      })
      .catch(() => {})
      .finally(() => setLoadingData(false));
  }, [
    searchParams,
    funding,
    country,
    search,
    top100,
    top200,
    top300,
    fee10,
    fee20,
    fee30,
    fee40,
  ]);
  console.log(data);

  useEffect(() => {
    getData();

    setLoadingCountries(true);
    getUniversityCountries()
      .then((res) => {
        setCountries(res);
      })
      .finally(() => setLoadingCountries(false));
  }, [getData, search, searchParams]);
  return (
    <div className='flex md:flex-row flex-col h-[85vh]'>
      <div className='flex flex-col gap-2'>
        <div className='flex md:flex-col flex-row justify-between mx-auto md:mx-0 gap-2'>
          <div className='lg:border-2 lg:rounded-lg p-2 lg:shadow-md lg:w-60'>
            <p className='font-semibold mb-2'>
              {t('universities.university_type')}
            </p>
            <div className='lg:flex-col flex flex-row gap-2 flex-wrap justify-center'>
              <Button
                className='w-36 h-8 md:h-8 lg:h-8'
                onClick={() => setTab('foreign')}
                text={t('universities.foreign')}
              />
              <Button
                className='w-36 h-8 md:h-8 lg:h-8'
                onClick={() => setTab('local')}
                text={t('universities.local.local')}
              />
              <Button
                className='w-36 h-8 md:h-8 lg:h-8'
                color={'primary'}
                text={t('universities.special_local.special_local')}
              />
            </div>
          </div>
        </div>
        {isMobile && (
          <div className=' w-full fixed bottom-8 flex flex-row justify-center'>
            <div
              className='rounded-3xl bg-primary text-white px-4 py-2
          flex flex-row items-center gap-1'
              onClick={() => setId(uuid)}
            >
              <p>{t('universities.special_local.learn_more')}</p>
            </div>
          </div>
        )}
      </div>

      <div
        className='flex flex-col lg:text-sm md:text-sm text-xs 
        h-[90vh] overflow-y-auto w-full px-2 gap-4'
        ref={scrollRef}
      >
        {isMobile ? (
          <div>
            <MobileSidebarModal
              headerText={t('universities.special_local.learn_more')}
              uuid={uuid}
              onClose={() => setId('')}
            >
              <Description />
            </MobileSidebarModal>
          </div>
        ) : (
          <div>
            <Description />
          </div>
        )}
        <div className='flex flex-wrap justify-center gap-x-6 gap-y-4'>
          {loadingData ? (
            <div className='pt-6'>
              <SimpleLoader className={'w-12 h-12'} />
            </div>
          ) : !data?.length ? (
            <div className='w-full flex flex-row justify-center text-gray-500 py-8 text-lg'>
              {/* No universities */}
            </div>
          ) : (
            data?.map((d) => (
              <div
                key={d?.id}
                style={{ borderColor: d?.color }}
                className='rounded-lg border flex flex-col justify-between
                  md:w-64 md:h-96 lg:w-64 lg:h-[22rem] w-[40vw] h-72 cursor-pointer
                  hover:border-2 hover:shadow-xl'
                onClick={() => window.open(`/universities/${d?.id}`, '_blank')}
              >
                <div className='md:w-48 md:h-40 lg:w-48 lg:h-40 h-20 p-2 m-auto'>
                  <img
                    src={d?.logo}
                    alt='logo'
                    className='md:w-48 md:h-40 lg:w-48 lg:h-40 w-full h-full
                    rounded-full object-contain'
                  />
                </div>
                <div className='flex flex-col justify-between px-4 py-2 h-full'>
                  <div className='flex flex-col gap-2 h-full justify-between'>
                    <div className='font-medium md:text-base text-sm leading-tight tracking-wide'>
                      {d?.universityName}
                    </div>
                    <div
                      className='text-gray-500 max-h-20 text-ellipsis overflow-hidden 
                        md:leading-normal leading-[1.1]'
                    >
                      <span style={{ color: d?.color }}>
                        {getTranlated('information', d?.ratingInformation[0])}
                      </span>{' '}
                      {getTranlated('name', d?.ratingInformation[0])}
                    </div>
                    <div
                      style={{ color: d?.color }}
                      className='font-medium md:text-base text-sm'
                    >{`from $ ${d?.tuitionCost.tuitionCost}`}</div>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default SpecialLocal;
