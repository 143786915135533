import { useEffect, useState } from 'react';
import { getGroupRoadmapStats } from '../../../../services/roadmapTask/roadmapStatsService';
import {ArrowLeftOnRectangleIcon} from '@heroicons/react/24/outline';
import SimpleLoader from '../../../../components/Loader/SimpleLoader';
import { Button } from '../../../../components/forms/Buttons/Button';
import CustomDialog from '../../../../components/modals/CustomDialog/CustomDialog';
import {
  postTransfer,
  getTransfer,
} from '../../../../services/auth/authService';

const GroupRoadmapStats = ({ onSelect, org, goBack, orgs }) => {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [transferStatus, setTransferStatus] = useState('');
  const [transferMessage, setTransferMessage] = useState('');
  const [dialogLoading, setDialogLoading] = useState(false);

  // const colors = [
  //   'from-red-500 to-red-200',
  //   'from-violet-600 to-violet-200',
  //   'from-green-400 to-blue-400',
  // ];

  useEffect(() => {
    if (org) {
      setLoading(true);
      getGroupRoadmapStats({ page_size: 100, organization: org?.uuid })
        ?.then((res) => {
          setGroups(res?.results);
        })
        ?.finally(() => setLoading(false));
    }
  }, [org]);

  const onClose = () => {
    setOpen(false);
  };

  const handleOpenDialog = async () => {
    setDialogLoading(true); // Start loading
    try {
      const data = await getTransfer();
      setTransferStatus(data.status);
      setTransferMessage(data.message);
    } catch (error) {
      console.error('Error fetching transfer data:', error);
      setTransferStatus('Error');
      setTransferMessage('Failed to fetch data.');
    } finally {
      setDialogLoading(false); // Stop loading
      setOpen(true); // Open dialog
    }
  };

  const handlePostTransfer = async () => {
    setDialogLoading(true); // Start loading
    try {
      const data = await postTransfer();
      setTransferStatus(data.status);
      setTransferMessage(data.message);
    } catch (error) {
      console.error('Error processing transfer:', error);
      setTransferStatus('Error');
      setTransferMessage('Failed to process the transfer.');
    } finally {
      setDialogLoading(false); // Stop loading
    }
  };

  return (
    <>
      <p className='font-bold text-lg'>{org?.name}</p>
<div className="flex items-center space-x-4">
  {/* Back Button */}
  <button
    onClick={() => goBack()}
    className="flex items-center justify-center bg-blue-100 text-primary hover:bg-primary hover:text-white rounded-lg px-4 py-2"
  >
    Back
  </button>

  {/* Synchronizing Button */}
  {orgs.length === 1 && (
    <button
      onClick={handleOpenDialog}
      className="flex items-center justify-center bg-blue-100 text-primary hover:bg-primary hover:text-white rounded-lg px-4 py-2 w-full max-w-lg"
    >
      <ArrowLeftOnRectangleIcon className="h-5 mr-2" />
      Synchronizing students' accounts from bil.org.kz.
    </button>
  )}
</div>


    <div>
  <CustomDialog isOpen={open} closeModal={() => setOpen(false)}>
    {dialogLoading ? (
      <div className="flex items-center justify-center h-20">
        <div className="loader border-t-2 border-primary rounded-full w-8 h-8 animate-spin"></div>
        <span className="ml-2 text-gray-600">Loading...</span>
      </div>
    ) : (
      <div className="p-4 text-gray-800">
        {/* Display status and message */}
        <p className="mb-4">
          To synchronize your school with bil.org.kz or check the status of the transfer, click the <strong>UPDATE button</strong>, noting that the process may take <strong>some time.</strong>
        </p>
        <p className="mb-2">
          <strong>Status:</strong> {transferStatus}
        </p>
        <p className="mb-4">
          <strong>Message:</strong> {transferMessage}
        </p>
        {/* Button to trigger postTransfer */}
        <button
          onClick={handlePostTransfer}
          className="bg-primary text-white rounded-lg p-2 mt-4 hover:bg-primary-dark transition duration-300"
        >
          Update
        </button>
      </div>
    )}
  </CustomDialog>
</div>

      <div className='flex flex-row flex-wrap py-4 gap-4'>
        {loading ? (
          <SimpleLoader className='h-8' />
        ) : (
          groups?.map((group, i) => {
            return (
              <div
                onClick={() => onSelect({ name: group.name, uuid: group.uuid })}
                key={group.uuid}
                className={`relative w-80 h-48 rounded-xl border-4 cursor-pointer
        flex flex-col justify-between text-lgfont-bold bg-gradient-to-tr group from-green-400 to-blue-400`}
              >
                <div className='flex flex-col h-full justify-between p-4 '>
                  <div className='flex flex-col'>
                    <span className='text-white text-6xl font-black'>
                      {group.name}
                    </span>
                  </div>
                </div>
                <div className='bg-white flex flex-row justify-between p-1 px-2'>
                  <div className='flex flex-row gap-4'>
                    <div className='flex flex-col items-center -space-y-2'>
                      <span className='text-lg font-semibold'>
                        {group.total_students}
                      </span>
                      <span className='text-gray-500 text-sm'>Students</span>
                    </div>
                    <div className='flex flex-col items-center -space-y-2'>
                      <span className='text-lg font-semibold'>
                        {group.has_roadmap}
                      </span>
                      <span className='text-gray-500 text-sm'>
                        With Roadmap
                      </span>
                    </div>
                    <div className='flex flex-col items-center -space-y-2'>
                      <span className='text-lg font-semibold'>
                        {group.doing_roadmap}
                      </span>
                      <span className='text-gray-500 text-sm'>In Progress</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
};

export default GroupRoadmapStats;
