import React, { useState } from 'react';
import ViewTask from '../CreateRoadmap/EditPart/BREAD/ViewTask';
import { patchRoadmapSubtask } from '../../../services/roadmapTask/roadmapSubtaskService';
import Checkbox from '../../../components/forms/Checkbox/Checkbox';
import { format } from 'date-fns';
import {
  CalendarIcon,
  ExclamationCircleIcon,
} from '@heroicons/react/24/outline';
import { useMediaQuery } from 'react-responsive';

const SubtaskTable = ({ tasks, getTasksData }) => {
  const isMobile = useMediaQuery({ query: `(max-width: 1024px)` });
  const today = new Date();
  return (
    <div className='border rounded-2xl overflow-hidden'>
      <table className='w-full lg:text-lg text-sm overflow-hidden'>
        <thead>
          <th className='w-fit p-2 md:px-4'>Status</th>
          <th className='text-left w-1/3 p-2 px-4'>Title</th>
          <th className='text-left w-1/3 p-2 px-4'>Parent task</th>
          {!isMobile && <th className='w-fit p-2 px-4'>Start date</th>}
          {!isMobile && <th className='w-fit p-2 px-4'>Deadline</th>}
          <th className='w-full p-2 px-4'>Progress</th>
          <th className='min-w-[5] p-2 px-4'></th>
        </thead>
        <tbody>
          {tasks?.map((subtask) => (
            <tr
              className={`${
                new Date(subtask.deadline) < today && !subtask.done
                  ? 'bg-red-100'
                  : 'odd:bg-slate-100'
              }`}
              key={subtask?.uuid}
            >
              <td className=' flex-row items-center justify-center p-2 px-4 gap-1 lg:flex hidden'>
                <div className='flex flex-row items-center gap-1 justify-center'>
                  <CalendarIcon className='h-4 invisible' />
                  {new Date(subtask.deadline) < today && !subtask.done ? (
                    <ExclamationCircleIcon
                      className='h-5 text-error'
                      title='Task is expired'
                    />
                  ) : (
                    <CalendarIcon className='h-5 text-primary' />
                  )}
                </div>
              </td>
              <td className='lg:hidden'>
                <div className='flex flex-row justify-center items-center'>
                  <Checkbox
                    checked={subtask?.done}
                    onChange={() => {
                      const body = new FormData();
                      body.append('done', !subtask?.done);
                      patchRoadmapSubtask(subtask?.uuid, body)
                        .then(() => {
                          getTasksData();
                        })
                        .catch(() => {});
                    }}
                    disabled={subtask.parent?.status === 'finished'}
                  />
                </div>
              </td>
              <td className='p-2 lg:px-4 lg:text-base sm:text-sm text-xs'>
                {subtask.name}
              </td>
              <td className='p-2 lg:px-4 lg:text-base sm:text-sm text-xs'>
                {subtask.parent.name}
              </td>

              {isMobile ? (
                <>
                  <td
                    className='flex sm:flex-row flex-col gap-2 justify-center items-center p-2 lg:px-4 px-0
                    lg:text-base text-sm'
                  >
                    {subtask.start_date && (
                      <div>
                        <span className='text-xs lg:hidden self-start text-secondary'>
                          Start date
                        </span>
                        <div className='lg:text-base text-xs p-1 rounded bg-gray-200 lg:w-28 w-20 text-center'>
                          {format(
                            new Date(subtask.parent.start_date),
                            'MMM d, yyyy',
                          )}
                        </div>
                      </div>
                    )}
                    {subtask.deadline && (
                      <div>
                        <span className='text-xs lg:hidden self-start text-secondary'>
                          Deadline
                        </span>
                        <div className='lg:text-base text-xs p-1 rounded bg-gray-200 lg:w-28 w-20 text-center'>
                          {format(new Date(subtask.deadline), 'MMM d, yyyy')}
                        </div>
                      </div>
                    )}
                  </td>
                </>
              ) : (
                <>
                  {' '}
                  <td className=' items-center p-2 px-4'>
                    {subtask.start_date && (
                      <div className='p-1 rounded bg-gray-200 xl:w-32 w-28 text-center xl:text-base text-sm'>
                        {format(new Date(subtask.start_date), 'MMM d, yyyy')}
                      </div>
                    )}
                  </td>
                  <td className=' items-center p-2 px-4'>
                    {subtask.deadline && (
                      <div className='p-1 rounded bg-gray-200 xl:w-32 w-28 text-center xl:text-base text-sm'>
                        {format(new Date(subtask.deadline), 'MMM d, yyyy')}
                      </div>
                    )}
                  </td>
                  <td className=''>
                    <div className='flex flex-row justify-center'>
                      <Checkbox
                        checked={subtask?.done}
                        onChange={() => {
                          const body = new FormData();
                          body.append('done', !subtask?.done);
                          patchRoadmapSubtask(subtask?.uuid, body)
                            .then(() => {
                              getTasksData();
                            })
                            .catch(() => {});
                        }}
                        disabled={subtask.parent?.status === 'finished'}
                      />
                    </div>
                  </td>
                </>
              )}
              <td className='p-2 text-xs'>
                <ViewTask task={subtask} />
              </td>
            </tr>
          ))}

          {/* {task?.status !== 'finished' && isOpen.get(task?.uuid) && (
                <tr className='border-b last:border-b-0 hover:bg-gray-100 h-10'>
                  <td className='py-2 border-r px-2'>
                    <div className='flex flex-row items-center gap-1 justify-center'></div>
                  </td>
                  <td className='lg:text-bas text-sm border-r px-2'></td>
                  <td className='font-light lg:text-base text-sm text-center border-r px-2'></td>
                  <td
                    className={`lg:text-base text-sm pl-1 pr-2 w-48 border-r px-2`}
                  ></td>
                  <td className='text-center border-r px-2'></td>
                  <td className=''></td>
                </tr>
              )} */}
        </tbody>
      </table>
    </div>
  );
};

export default SubtaskTable;
