import React, { useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { getParameters } from '../../../services/parameters/parametersService';
import { getSkills } from '../../../services/projects/skillsService';
import { postProject } from '../../../services/projects/projectService';
import { Button } from '../../../components/forms/Buttons/Button';
import { useTranslation } from 'react-i18next';
import { InformationCircleIcon } from '@heroicons/react/24/solid';
import CustomDialog from '../../../components/modals/CustomDialog/CustomDialog';
import ContentPart from './ContentPart';
import ImagePart from './ImagePart';
import { UserContext } from '../../../utils/contexts/UserContext';
import { useNavigate } from 'react-router-dom';

export default function CreateProject() {
  const [t] = useTranslation();
  const { user } = useContext(UserContext);
  const navigate = useNavigate();

  const [levels, setLevels] = useState([]);
  const [subjects, setSubjects] = useState([]);
  // const [tags] = useState([]);
  const [bgImageUrl, setBgImageUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  // const fileRef = useRef(null);
  const [isImagePart, setIsImagePart] = useState(false);
  const [open, setOpen] = useState(false);
  const [body, setBody] = useState(null);
  const [content, setContent] = useState(null);

  useEffect(() => {
    getParameters({ category: 'level' }).then((res) => {
      setLevels(res?.results?.map((l) => ({ label: l?.name, value: l?.uuid })));
    });

    getSkills({ uuid: null }).then((res) => {
      setSubjects(
        res?.results
          ?.filter((s) => s?.level === 3 || s?.name === 'Programming')
          ?.map((s) => ({ label: s?.name, value: s?.uuid })),
      );
    });

    return () => {};
  }, []);

  const handleCreateProject = async () => {
    if (body && bgImageUrl) {
      setLoading(true);

      const formData = new FormData();

      for (var key in body) {
        if (body[key] && body[key] !== 'not_in') {
          formData.append(key, body[key]);
        }
      }
      formData.append('ai_img_url', bgImageUrl);

      postProject(formData)
        .then(() => {
          setLoading(false);
          toast.success('Success');
        })
        .catch((err) => {
          setLoading(false);
          toast.error('error');
        })
        .finally(() => {
          setLoading(false);
          navigate(-1);
        });
    } else {
      toast.error('Please select image');
    }
  };

  const onClose = () => {
    setOpen(false);
  };

  return (
    <div className='mx-2 md:m-0'>
      <CustomDialog
        headerText={t('Information')}
        isOpen={open}
        closeModal={() => onClose()}
        // ConfirmType='success'
        // confirmText={t('buttons.create')}
      >
        <p>
          <span className=' font-semibold'>{t("create_project.instructions.title")}</span> {t("create_project.instructions.title_info")}
           <br />
          <br />
          <span className=' font-semibold'>.{t("create_project.instructions.subject")}</span> {t("create_project.instructions.subject_info")} <br />
          <br />
          <span className=' font-semibold'>{t("create_project.instructions.level")}</span> {t("create_project.instructions.level_info")} <br />
          <br />
          <span className=' font-semibold'>{t("create_project.instructions.description")}</span> {t("create_project.instructions.description_info")}
          <br />
          <br />
          <span className=' font-semibold'>
            {t("create_project.instructions.essential_tools_resources")}
          </span>{' '}
          {t("create_project.instructions.essential_tools_resources_info")} <br />
          <br />
          <span className=' font-semibold'>{t("create_project.instructions.roadmap_with_tasks")}</span> {t("create_project.instructions.roadmap_with_tasks_info")} <br />
          <br />
          <span className=' font-semibold'>{t("create_project.instructions.evaluation_criteria")}</span> {t("create_project.instructions.evaluation_criteria_info")} <br />
          <br />
          <span className=' font-semibold'>{t("create_project.instructions.tags")}</span> {t("create_project.instructions.tags_info")}
        </p>
        <div>
          <Button type='button' text={t("create_project.accept")} onClick={() => onClose()} />
        </div>
      </CustomDialog>
      <div className='flex flex-row w-full gap-2 mb-4'>
        <h1 className=' text-xl font-semibold'>{t("create_project.project_constructor")}</h1>
        <Button
          text={t('create_project.instructions_button')}
          Icon={InformationCircleIcon}
          className='max-h-8'
          onClick={() => {
            setOpen(true);
          }}
        />
        {/* <button
          className={`h-8 w-8 p-1 rounded-md group relative text-center inline-flex items-center transition duration-100 ease-in disabled:cursor-default bg-yellow-600 text-white hover:bg-yellow-700`}
          >
          <InformationCircleIcon className='h-6' />
        </button> */}
      </div>
      {user?.roles?.some((role) => role?.name === 'STUDENT') && (
        <p className='italic mb-2'>
          <span className='text-red-600'>*</span> {t("create_project.remainder")}
        </p>
      )}
      <div className='flex-col border shadow-md rounded-md p-4'>
        {!isImagePart ? (
          <ContentPart
            setBody={setBody}
            content={content}
            setContent={setContent}
            setIsImagePart={setIsImagePart}
            levels={levels}
            subjects={subjects}
          />
        ) : (
          <ImagePart
            content={content}
            loading={loading}
            setIsImagePart={setIsImagePart}
            bgImageUrl={bgImageUrl}
            setBgImageUrl={setBgImageUrl}
            handleCreateProject={handleCreateProject}
          />
        )}
      </div>
    </div>
  );
}
