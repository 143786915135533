import { useMemo, useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon, ArrowLeftOnRectangleIcon } from '@heroicons/react/24/outline';
import { Button } from '../../../../components/forms/Buttons/Button';
import ReactApexChart from 'react-apexcharts';
import CustomDialog from '../../../../components/modals/CustomDialog/CustomDialog';
import {
  postTransfer,
  getTransfer,
} from '../../../../services/auth/authService';

const calculateAggregates = (orgs) => {
  const totalStudents = orgs.reduce(
    (sum, org) => sum + (org.total_students || 0),
    0,
  );
  const totalGenerated = orgs.reduce(
    (sum, org) => sum + (org.has_roadmap || 0),
    0,
  );
  const totalInProgress = orgs.reduce(
    (sum, org) => sum + (org.doing_roadmap || 0),
    0,
  );

  return {
    name: 'All Schools',
    total_students: totalStudents,
    has_roadmap: totalGenerated,
    doing_roadmap: totalInProgress,
    percent_gen:
      totalStudents > 0
        ? Math.ceil((totalGenerated / totalStudents) * 100) // Sum of Generated / Sum of Total Students
        : 0,
    percent_progress:
      totalGenerated > 0
        ? Math.ceil((totalInProgress / totalGenerated) * 100) // Sum of In Progress / Sum of Generated
        : 0,
  };
};



const OrgRoadmapStats = ({ onSelect, orgs, setOrgs }) => {
  const aggregatedData = useMemo(
    () => calculateAggregates(orgs),
    [orgs, calculateAggregates],
  );
  const [ordering, setOrdering] = useState('has_roadmap');
  const [view, setView] = useState('table'); // State to toggle between table and chart view


  const headers = useMemo(
    () => [
      { title: 'School', key: 'name' },
      { title: 'Total Students', key: 'total_students' },
      { title: 'Roadmaps Generated', key: 'has_roadmap' },
      { title: 'Generated Percentage', key: 'percent_gen' },
      { title: 'Roadmaps In Progress', key: 'doing_roadmap' },
      { title: 'In Progress Percentage', key: 'percent_progress' },
    ],
    [],
  );




  useEffect(() => {
    setOrgs(
      (prev) =>
        prev
          .map((org) => ({
            ...org,
            percent_gen: org.total_students
              ? Math.round((org.has_roadmap / org.total_students) * 100) // Percent Generated (rounded)
              : 0,
            percent_progress: org.has_roadmap
              ? Math.round((org.doing_roadmap / org.has_roadmap) * 100) // Percent In Progress (rounded)
              : 0,
          }))
          .sort((a, b) => b.has_roadmap - a.has_roadmap), // Sort by Roadmaps Generated
    );
  }, [setOrgs]);

  const reorder = (col) => {
    let s = 1;
    if (col === 'name') {
      s = -1;
    }
    if (ordering === `-${col}`) {
      setOrdering(col);
      setOrgs((prev) => prev.sort((a, b) => (a[col] > b[col] ? s : -s)));
    } else if (ordering === col) {
      setOrdering('-name');
      s = -1;
      setOrgs((prev) => prev.sort((a, b) => (a.name < b.name ? s : -s)));
    } else {
      setOrdering(`-${col}`);
      setOrgs((prev) => prev.sort((a, b) => (a[col] < b[col] ? s : -s)));
    }
  };

  const sortedOrgs = [...orgs].sort((a, b) => {
    const aPercentage = a.has_roadmap / a.total_students;
    const bPercentage = b.has_roadmap / b.total_students;
    return bPercentage - aPercentage;
  });

  // Chart configuration
  const chartOptions = {
    chart: {
      type: 'bar',
      height: sortedOrgs.length * 50, // Dynamically adjust height based on number of schools
      stacked: true,
    },
    plotOptions: {
      bar: {
        horizontal: true,
        barHeight: '80%', // Adjust bar height for better readability
      },
    },
    dataLabels: {
      enabled: true,
      formatter: (val) => `${val.toFixed(0)}%`,
      style: {
        fontSize: '18px',
      },
    },
    colors: ['#55c447', '#FFA500', '#427ef5'],
    xaxis: {
      categories: sortedOrgs.map((org) => org.name), // Display school names on y-axis, sorted by percentage
      title: {
        text: 'Percentage (%)',
      },
    },
    yaxis: {
      title: {
        text: 'School',
      },
      labels: {
        style: {
          fontSize: '18px',
        },
      },
    },

    title: {
      text: 'School Data',
      align: 'left',
    },
    legend: {
      position: 'top',
      horizontalAlign: 'left', // Center align the legend
      fontSize: '14px',
      offsetY: -10, // Move the legend closer to the chart
    },
  };

  // Series data for stacked bars, based on sorted orgs
  const chartSeries = [
    {
      name: 'Roadmaps In Progress',
      data: sortedOrgs.map(
        (org) => (100 * org.doing_roadmap) / org.total_students,
      ),
    },
    {
      name: 'Roadmaps Generated (Not In Progress)',
      data: sortedOrgs.map(
        (org) =>
          (100 * (org.has_roadmap - org.doing_roadmap)) / org.total_students,
      ),
    },
    {
      name: 'No Roadmaps',
      data: sortedOrgs.map(
        (org) =>
          (100 * (org.total_students - org.has_roadmap)) / org.total_students,
      ),
    },
  ];

  return (
    <div className='flex flex-col p-6 border rounded-lg'>
      <div className='flex flex-row justify-between items-center mb-4'>
        <div className='flex flex-row gap-3'>
          <Button
            text={'Table'}
            rounded
            color={view === 'table' ? 'primary' : ''}
            onClick={() => setView('table')}
          />
          <Button
            text={'Chart'}
            rounded
            color={view === 'chart' ? 'primary' : ''}
            onClick={() => setView('chart')}
          />
        </div>
      </div>

      {view === 'chart' ? (
        <ReactApexChart
          options={chartOptions}
          series={chartSeries}
          type='bar'
          height={orgs.length * 50}
        />
      ) : (
        <table className='table-auto w-full mt-4'>
          <thead className='bg-gray-100'>
            <tr>
              <th className='text-center p-2 w-1/12'>#</th>
              {headers?.map((h, i) => (
                <th
                  className={`${
                    ['percent_gen', 'percent_progress'].includes(h.key)
                      ? '' // Add blue background and white text for percentage columns
                      : ''
                  } ${!i && 'text-left'} w-4 cursor-pointer`}
                  key={h.key}
                  onClick={() => reorder(h.key)}
                >
                  <div
                    className={`flex flex-row items-center gap-1 ${
                      !i ? 'text-left' : 'justify-center'
                    }`}
                  >
                    {h.title}
                    {h.key === ordering ? (
                      <ChevronUpIcon className='h-4' />
                    ) : `-${h.key}` === ordering ? (
                      <ChevronDownIcon className='h-4' />
                    ) : (
                      <ChevronDownIcon className='h-4 invisible' />
                    )}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {/* Aggregated row */}
            <tr className='font-bold bg-blue-500 text-white'>
              <td className='text-center p-2 px-4 w-1/12'>#</td>
              {headers?.map((h, i) => (
                <td
                  key={h.key}
                  className={`${
                    h.key === 'name'
                      ? 'text-left' // Align "All Schools" with the "School" column
                      : ['percent_gen', 'percent_progress'].includes(h.key)
                      ? 'text-center bg-blue-500 text-white'
                      : 'text-center'
                  }`}
                >
                  {h.key === 'name'
                    ? 'All Schools' // Render "All Schools" in the "School" column
                    : ['percent_gen', 'percent_progress'].includes(h.key)
                    ? `${aggregatedData[h.key]}%` // Render percentages
                    : aggregatedData[h.key] || '-'}{' '}
                </td>
              ))}
            </tr>

            {/* Original rows */}
            {orgs?.map((s, i) => (
              <tr key={s?.uuid} className='even:bg-gray-100'>
                <td className='text-center p-2 px-4 w-1/12'>{i + 1}</td>
                {headers?.map((h, i) => (
                  <td
                    className={`${
                      i ? 'text-center' : 'cursor-pointer hover:text-primary'
                    } w-4 ${
                      ['percent_gen', 'percent_progress'].includes(h.key)
                        ? ''
                        : ''
                    }`}
                    onClick={() => {
                      if (i) return;
                      onSelect({ name: s.name, uuid: s.uuid });
                    }}
                    key={h.key}
                  >
                    {['percent_gen', 'percent_progress'].includes(h.key)
                      ? `${s[h.key]}%`
                      : s[h.key]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default OrgRoadmapStats;
