import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../utils/hooks/useAuth';
import { EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline';
import { UserContext } from '../../utils/contexts/UserContext';
import SSOBilOrgKz from './SSOBilOrgKz';
import login_guy from '../../assets/images/login_guy.png';
import ruler from '../../assets/images/ruler.png';
import watch from '../../assets/images/watch.png';
import MyIDP from '../../assets/images/MyIDP.png';
import laptop from '../../assets/images/laptop.png';
import gear_1 from '../../assets/images/gear_1.png';
import gear_2 from '../../assets/images/gear_2.png';
import gear_3 from '../../assets/images/gear_3.png';
import planet from '../../assets/images/planet.png';
import './login.css';

export default function EmptyLogin() {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const {
    // formState: { errors },
    register,
    handleSubmit,
  } = useForm();

  const { user } = useContext(UserContext);
  const { loginUser, error } = useAuth();

  const [isVisible, setIsVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {
    setLoading(true);

    await loginUser(data).finally(() => {
      setLoading(false);
    });
  };

  useEffect(() => {
    if (user?.uuid) {
      if (user?.root_organization === 'APA') {
        navigate({
          pathname: `/tests`,
          state: { user: user },
        });
      }
      if (user?.roles?.some((role) => role.name === 'STUDENT')) {
        navigate({
          pathname: `/home`,
          state: { user: user },
        });
      }
      if (
        user?.roles?.some((role) => role.name === 'PROJECTADMIN') ||
        user?.roles.some((role) => role.name === 'PROJECTREVIEWER')
      ) {
        navigate({
          pathname: `/home`,
          state: { user: user },
        });
      }
    }

    return () => { };
  }, [user, navigate]);

  return (
    <div className="flex flex-row justify-end h-full w-full bg-[url('./assets/images/login_bg.png')] bg-no-repeat bg-cover">
      <img
        alt='logo'
        src={MyIDP}
        className='absolute sm:left-6 sm:top-1 2xl:w-20 w-16 pt-4 left-5 top-2 pointer-events-none select-none z-10 opacity-80'
      />
      <div className='xl:w-1/2 xl:flex hidden flex-col justify-center items-center pointer-events-none select-none'>
        <div className='relative w-[24rem]'>
          {/* <img 
            alt='part of (ruler)'
            className='w-32 -top-[16rem] -left-12 delay-300 absolute animate-[bounce_3s_linear_infinite]'
            src={ruler}
            unselectable='on'
            style={{ animationDelay: '-2s' }}
          /> */}
          <div className='absolute -bottom-[18rem] flex flex-col -space-y-[2.1rem] space-x-[3.25rem] animate-[bounce_3s_linear_infinite]'>
            <img
              className='w-20 animate-[spin_3s_linear_infinite]'
              alt='part of logo(gear 1)'
              src={gear_1}
            />
            <div className='scale-x-[-1]'>
              <img
                className='w-20 animate-[spin_3s_linear_infinite]'
                alt='part of logo(gear 2)'
                src={gear_2}
              />
            </div>
            <div className='-translate-x-[3.75rem] -translate-y-[0.8rem]'>
              <img
                className='w-20 animate-[spin_3s_linear_infinite]'
                alt='part of logo(gear 3)'
                src={gear_3}
              />
            </div>
          </div>
          <img
            alt='part of logo(student)'
            className='z-[1] absolute -top-[16rem] '
            src={login_guy}
          />
          <img
            alt='part of logo (laptop)'
            className='w-40 z-[2] left-40 -top-20 absolute animate-[bounce_3.5s_linear_infinite]'
            src={laptop}
          />
          <img
            alt='part of logo (planet)'
            className='w-16 z-[2] left-[4.8rem] -top-[5.55rem] absolute animate-[bounce_2s_linear_infinite]'
            src={planet}
          />
          <img
            alt='part of logo (watch)'
            className='w-48 absolute -top-[20rem] -right-16 animate-[bounce_3s_linear_infinite]'
            src={watch}
            style={{ animationDelay: '-1s' }}
          />
        </div>
      </div>
      <div className='flex flex-col justify-center items-center xl:w-1/2 w-full bg-inherit'>
        <form
          className='flex flex-col items-center gap-6 rounded-2xl p-4 bg-white bg-opacity-70 ' // border border-purple-600'
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className='text-6xl text-purple-600 font-bold'>Log in</div>
          <div className='flex flex-row gap-4 justify-center items-center w-full'>
            <div className='border-b border-purple-600 sm:w-40 w-24' />
            <div className='break-keep text-purple-600 text-sm min-w-fit'>
              OR
            </div>
            <div className='border-b border-purple-600 sm:w-40 w-24' />
          </div>
          <div className='flex flex-col group relative'>
            <input
              id='username'
              type='text'
              required
              className='border rounded-xl border-purple-600 sm:w-96 w-64 peer outline-none px-2 py-2'
              {...register('username', {
                required: true,
                maxLength: 64,
              })}
            />
            <label
              htmlFor='username'
              className='text-purple-600 text-sm top-3 mx-3 group-focus-within:-top-5 peer-valid:-top-5 group-focus-within:text-xs peer-valid:text-xs absolute transition-all delay-100 cursor-text'
            >
              {'Username'}
            </label>
          </div>
          <div className='flex flex-col group relative'>
            <input
              id='password'
              type={isVisible ? 'text' : 'password'}
              required
              className='border rounded-xl border-purple-600 sm:w-96 w-64 peer outline-none px-2 py-2'
              {...register('password', {
                required: true,
                maxLength: 64,
              })}
            />
            <div className='absolute right-2 bottom-2.5 cursor-pointer text-purple-600'>
              {isVisible ? (
                <EyeIcon className='h-6' onClick={() => setIsVisible(false)} />
              ) : (
                <EyeSlashIcon
                  className='h-6'
                  onClick={() => setIsVisible(true)}
                />
              )}
            </div>
            <label
              htmlFor='password'
              className='text-purple-600 text-sm top-3 mx-3 group-focus-within:-top-5 peer-valid:-top-5 group-focus-within:text-xs peer-valid:text-xs absolute transition-all delay-100 cursor-text'
            >
              {true ? t('Password') : '********'}
            </label>
          </div>
          <div className='flex flex-row justify-center'>
            <button
              className='bg-purple-600 hover:bg-purple-500 text-center text-white p-2 rounded-xl shadow-2xl drop-shadow-2xl sm:w-96 w-64'
              type='submit'
              disabled={loading}
            >
              {loading ? (
                <svg
                  role='status'
                  className='m-auto inline h-6 w-6 animate-spin text-cyan-800'
                  viewBox='0 0 100 101'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z'
                    fill='#E5E7EB'
                  />
                  <path
                    d='M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z'
                    fill='currentColor'
                  />
                </svg>
              ) : (
                'Log in'
              )}
            </button>
          </div>
          {error && !loading && (
            <div className='rounded-lg border bg-red-100 border-error text-error px-3 py-2'>
              {error?.data?.detail}
            </div>
          )}
        </form>
      </div>
    </div>
  );
}
