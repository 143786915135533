import bilAPI from '../api';

export const getCourses = async ({ uuid }) => {
  try {
    const res = await bilAPI.get(`/courses/courses/${uuid ? uuid + '/' : ''}`, {
      params: {},
    });
    return res.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const getStartedCourses = async (account) => {
  try {
    const res = await bilAPI.get('/courses/courses/get_started/', {
      params: { account },
    });
    return res.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const startCourse = async ({ uuid }) => {
  try {
    const res = await bilAPI.post(`/courses/courses/${uuid}/start/`);
    return res.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const postCourse = async ({ name, description, key_facts, duration, authors }) => {
  try {
    const res = await bilAPI.post('/courses/courses/', {
      name,
      description,
      key_facts,
      duration,
      authors,
    });
    return res.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const patchCourse = async ({ uuid, name, description, key_facts, duration, authors }) => {
  try {
    const res = await bilAPI.patch(`/courses/courses/${uuid}/`, {
      name,
      description,
      key_facts,
      duration,
      authors,
    });
    return res.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};

export const deleteCourse = async (uuid) => {
  try {
    const res = await bilAPI.delete(`/courses/courses/${uuid}/`);
    return res.data;
  } catch (error) {
    throw error.response?.data || error.message;
  }
};
