import bilAPI from '../api';
import contentAPI from '../contentApi';


export const sendChatMessage = (message, history, ai_tool_uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post('/aitools/chat/', { message, history, ai_tool_uuid })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const getAiTags = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/aitools/aitags/`, {
          params: { page_size: 100 },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getAiTools = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/aitools/aitools/`, {
          params: { page_size: 100 },
        })
        .then((res) => {
          resolve(res.data.results);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const getAiToolByUuid = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/aitools/aitools/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const addToFavorites = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/aitools/aitools/${uuid}/add_to_favorites/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const removeFromFavorites = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/aitools/aitools/${uuid}/remove_from_favorites/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

