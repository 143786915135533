import React, { useContext, useState } from 'react';
import RichTextEditor from '../../../../../components/forms/Inputs/RichTextEditor';
import { postUserQuestionAnswer } from '../../../../../services/courses/userAnswerService';
import { UserContext } from '../../../../../utils/contexts/UserContext';

const QuestionContent = ({ data, refreshData }) => {
  const [selected, setSelected] = useState();
  const { user } = useContext(UserContext);

  return (
    <div className="flex flex-col gap-4 px-6 py-4">
      {/* Ответы */}
      <div className="flex flex-col gap-2">
        {data?.answers.map((answer) => (
          <div
            key={answer.uuid}
            className={`p-4 rounded-lg transition-all duration-300 
              ${
                selected || data.user_answer
                  ? 'bg-blue-100'
                  : 'cursor-pointer hover:bg-blue-50'
              }
              ${
                data.correct_answer?.find((q) => q.uuid === answer.uuid) &&
                'bg-green-200'
             }
              ${
                data.user_answer?.answer === answer.uuid &&
                'bg-yellow-100'
              }`}
            style={{
              wordWrap: 'break-word',
              whiteSpace: 'normal',
              textAlign: 'center',
            }}
            onClick={() => {
              if (selected || data.user_answer) return;
              setSelected(answer.uuid);
              postUserQuestionAnswer({
                question: data.uuid,
                answer: { answer: answer.uuid },
                user: user.uuid,
              }).then(() => refreshData(false));
            }}
          >
            <span className="block font-medium text-gray-700">{answer?.answer?.answer}</span>
            {data.user_answer?.answer === answer.uuid && (
              <span className="text-xs text-gray-500 block mt-2">Ваш ответ</span>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuestionContent;
