import bilAPI from '../api';
import contentAPI from '../contentApi';

export const getAccount = (uuid) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/account/${uuid}/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchAccount = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .patch(`/auth/account/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};

export const patchAccountPicture = (uuid, body) => {
  return new Promise((resolve, reject) => {
    try {
      contentAPI
        .patch(`/auth/account/${uuid}/`, body)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};


export const postTransfer = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .post(`/auth/transer_from_bil/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error: ', err);
        });
    } catch (error) {
      reject('system error: ', error);
    }
  });
};

export const getTransfer = () => {
  return new Promise((resolve, reject) => {
    try {
      bilAPI
        .get(`/auth/transer_from_bil/`)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject('axios error', err);
        });
    } catch (error) {
      reject('system error', error);
    }
  });
};
