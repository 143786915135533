import React, { useState } from 'react';
import { Button } from '../../../../../components/forms/Buttons/Button';
import Input from '../../../../../components/forms/Inputs/Input';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TextArea from '../../../../../components/forms/Inputs/TextArea';
import { postCourse } from '../../../../../services/courses/courseService';

const Add = ({ refreshData }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [keyFacts, setKeyFacts] = useState(['']);
  const [keyFactError, setKeyFactError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onClose = () => {
    reset();
    setKeyFacts(['']);
    setKeyFactError(false);
    setOpen(false);
  };

  const onSubmit = async (data) => {
    const hasFilledKeyFacts = keyFacts.some((fact) => fact.trim() !== '');
    if (!hasFilledKeyFacts) {
      setKeyFactError(true);
      return;
    }

    const payload = {
      ...data,
      key_facts: keyFacts,
      duration: parseInt(data.duration, 10),
    };

    try {
      await postCourse(payload);
      refreshData();
      onClose();
    } catch (error) {
      console.error('Failed to save course:', error);
    }
  };

  const handleAddKeyFact = () => {
    if (keyFacts.length < 3) {
      setKeyFacts([...keyFacts, '']);
    }
  };

  const handleKeyFactChange = (index, value) => {
    const updatedKeyFacts = [...keyFacts];
    updatedKeyFacts[index] = value;
    setKeyFacts(updatedKeyFacts);
    setKeyFactError(false);
  };

  const handleRemoveKeyFact = (index) => {
    const updatedKeyFacts = keyFacts.filter((_, i) => i !== index);
    setKeyFacts(updatedKeyFacts);
  };

  return (
    <>
      <Button text="Create New Course" onClick={() => setOpen(true)} />
      <CustomDialog isOpen={open} closeModal={onClose}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 pt-4"
        >
          <div className="w-full">
            <Input
              errorMsg={errors.name?.message}
              required
              label="Title"
              {...register('name', {
                required: 'Title is a required field',
              })}
            />
            <TextArea
              errorMsg={errors.description?.message}
              required
              rows={15}
              label="Description"
              {...register('description', {
                required: 'Description is a required field',
              })}
            />
            <Input
              errorMsg={errors.duration?.message}
              required
              label="Duration"
              type="number"
              defaultValue=""
              {...register('duration', {
                required: 'Duration is a required field',
                valueAsNumber: true,
                validate: (value) =>
                  value > 0 || 'Duration must be a positive number',
              })}
            />
            <div className="flex flex-col gap-2">
              <label className="font-semibold mb-2">
                Key Facts<span className="text-red-500"> *</span>
              </label>
              {keyFacts.map((fact, index) => (
                <div key={index} className="flex items-center gap-2">
                  <Input
                    value={fact}
                    onChange={(e) =>
                      handleKeyFactChange(index, e.target.value)
                    }
                    placeholder={`Key Fact ${index + 1}`}
                  />
                  <Button
                    text="-"
                    color="danger"
                    onClick={() => handleRemoveKeyFact(index)}
                    type="button"
                    disabled={keyFacts.length === 1}
                  />
                </div>
              ))}
              {keyFactError && (
                <p className="text-red-500 text-sm">
                  At least one Key Fact is required.
                </p>
              )}
              <Button
                text="+ Add Key Fact"
                onClick={handleAddKeyFact}
                type="button"
                color="primary"
                disabled={keyFacts.length >= 3}
              />
            </div>
            <div className="mt-4">
              <label className="font-semibold">Author</label>
              <p className="text-sm text-gray-700">
                {t('The current user will automatically be added as the author.')}
              </p>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-2">
            <Button
              color="secondary"
              text="Cancel"
              onClick={onClose}
              type="button"
            />
            <Button color="success" text="Create" type="submit" />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Add;
