import React, { useEffect, useState, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { getAiToolByUuid, sendChatMessage } from '../../../services/aiTools/aiToolsService';

export default function ToolsChat() {
  const { uuid } = useParams(); // Extract UUID from route parameters
  const location = useLocation(); // To receive initial state
  const [selectedTool, setSelectedTool] = useState(null); // Current AI tool
  const [chatMessages, setChatMessages] = useState([]);
  const [chatInput, setChatInput] = useState('');
  const [isTyping, setIsTyping] = useState(false); // Assistant "typing" indicator
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const chatContainerRef = useRef(null); // For handling chat scrolling

  // Fetch AI tool by UUID
  useEffect(() => {
    if (!uuid) {
      setError('No tool selected.');
      setLoading(false);
      return;
    }

    getAiToolByUuid(uuid)
      .then((tool) => {
        setSelectedTool(tool);
        setLoading(false);
      })
      .catch((err) => {
        console.error('Error fetching AI tool:', err);
        setError('Failed to load the selected AI tool. Please try again later.');
        setLoading(false);
      });
  }, [uuid]);

  // Handle initial message from navigation state
  // Handle initial message from navigation state
useEffect(() => {
  const initialMessage = location.state?.initialMessage;

  // Wait until `selectedTool` is loaded
  if (initialMessage && selectedTool) {
    handleInitialMessage({ role: 'user', content: initialMessage }); // Send initial message to endpoint without displaying
  }
}, [location.state, selectedTool]);


// Send the initial message to the endpoint
const handleInitialMessage = async (message) => {
  if (!selectedTool?.uuid) {
    console.error('UUID is missing:', selectedTool);
    return;
  }

  setIsTyping(true);
  console.log('Sending initial message with UUID:', selectedTool.uuid);

  try {
    const data = await sendChatMessage(
      message.content,
      [message], // Include only the initial message in the history
      selectedTool.uuid // Pass the UUID explicitly
    );

    const assistantMessage = { role: 'assistant', content: data.bot_response };
    setChatMessages((prev) => [...prev, assistantMessage]); // Display only the assistant's response
  } catch (error) {
    console.error('Error sending initial message:', error);
    setChatMessages((prev) => [
      ...prev,
      { role: 'assistant', content: 'An error occurred. Please try again.' },
    ]);
  } finally {
    setIsTyping(false);
  }
};


  // Handle sending a message
  const handleSendMessage = async () => {
    if (!chatInput.trim()) return;

    const userMessage = { role: 'user', content: chatInput };
    setChatMessages((prev) => [...prev, userMessage]);
    setChatInput('');
    setIsTyping(true);

    const history = [...chatMessages, userMessage].slice(-8); // Limit to last 8 messages

    try {
      const data = await sendChatMessage(chatInput, history, selectedTool?.uuid);

      const assistantMessage = { role: 'assistant', content: data.bot_response };
      setChatMessages((prev) => [...prev, assistantMessage]);
    } catch (error) {
      console.error('Error sending message:', error);
      setChatMessages((prev) => [
        ...prev,
        { role: 'assistant', content: 'An error occurred. Please try again.' },
      ]);
    } finally {
      setIsTyping(false);
    }
  };

  // Scroll to the bottom of the chat container whenever chatMessages or isTyping changes
  useEffect(() => {
    if (chatContainerRef.current) {
      setTimeout(() => {
        chatContainerRef.current.scrollTo({
          top: chatContainerRef.current.scrollHeight,
          behavior: 'smooth',
        });
      }, 1);
    }
  }, [chatMessages, isTyping]);

  if (loading) {
    return <p className="text-center">Loading...</p>;
  }

  if (error) {
    return <p className="text-center text-red-500">{error}</p>;
  }

  return (
    <div className="h-screen w-screen lg:w-full flex flex-col lg:flex-row gap-4 p-4">
      {/* Chat Section */}
      <div className="flex-1 flex flex-col border rounded-lg shadow bg-white h-screen">
        <div className="bg-blue-500 text-white p-4 rounded-t-lg font-bold text-left">
          Chat with AI
        </div>
        {/* Chat messages container */}
        <div
          ref={chatContainerRef}
          className="flex-1 overflow-y-auto p-4 space-y-4"
        >
          {selectedTool && (
            <div className="mb-4">
              <h2 className="font-bold text-lg">Selected Tool: {selectedTool.name}</h2>
              <p className="text-gray-600 text-sm">{selectedTool.description}</p>
            </div>
          )}
          {chatMessages.map((msg, index) => (
            <div
              key={index}
              className={`flex ${
                msg.role === 'user' ? 'justify-end' : 'justify-start'
              }`}
            >
              <div
                className={`px-4 py-2 rounded-lg max-w-full ${
                  msg.role === 'user'
                    ? 'bg-blue-500 text-white'
                    : 'bg-gray-100 text-gray-800'
                }`}
              >
                {msg.role === 'assistant' ? (
                  <ReactMarkdown
                    className="prose prose-sm prose-gray max-w-full"
                    remarkPlugins={[remarkGfm]}
                  >
                    {msg.content}
                  </ReactMarkdown>
                ) : (
                  msg.content
                )}
              </div>
            </div>
          ))}
          {isTyping && (
            <div className="flex justify-start">
              <div className="bg-gray-100 text-gray-800 px-4 py-2 rounded-lg">
                thinking...
              </div>
            </div>
          )}
        </div>
        {/* Input field container */}
        <div className="p-4 border-t bg-white sticky bottom-0">
          <div className="flex items-center gap-2">
            <textarea
              value={chatInput}
              onChange={(e) => setChatInput(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
              }}
              className="flex-1 p-2 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 resize-none"
              placeholder="Type a message..."
              rows={2}
            />
            <button
              onClick={handleSendMessage}
              className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
            >
              Send
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
