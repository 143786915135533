import React from 'react';
import TextContent from './TextContent';
import ProjectContent from './ProjectContent';
import QuestionContent from './QuestionContent';
import FileContent from './FileContent';
import VideoContent from './VideoContent';

const Content = ({ data, description }) => {
  return (
    <div className="flex flex-col justify-center items-center gap-4 px-4 py-6">
      {!data && (
        <div className="text-gray-500 italic text-center max-w-xl">
          {description || 'No content available for this lesson.'}
        </div>
      )}

      {data && (
        <>
          {/* Видео */}
          {data?.video_content && (
            <div className="w-full max-w-screen-lg bg-black rounded-md overflow-hidden shadow-md">
              <VideoContent
                url={data.video_content.url}
                className="w-full h-auto"
                style={{ aspectRatio: '16 / 9', margin: '0 auto' }}
              />
            </div>
          )}

          {/* Текст */}
          {data?.text_content && (
            <div className="px-6 py-4 bg-white shadow-md rounded-md w-full max-w-screen-lg border border-gray-200">
              <TextContent data={data.text_content} />
            </div>
          )}

          {/* Проект */}
          {data?.project_content && (
            <div className="px-6 py-4 bg-gradient-to-r from-blue-50 to-blue-100 shadow-md rounded-md w-full max-w-screen-lg">
              <ProjectContent data={data.project_content} />
            </div>
          )}

          {/* Вопросы */}
          {data?.question_content && (
            <div className="px-6 py-4 bg-white shadow-md rounded-md w-full max-w-screen-lg border border-gray-200">
              <h3 className="text-lg font-bold text-gray-800 mb-2">Questions</h3>
              <QuestionContent data={data.question_content} />
            </div>
          )}

          {/* Файлы */}
          {data?.file_content && (
            <div className="px-6 py-4 bg-white shadow-md rounded-md w-full max-w-screen-lg border border-gray-200 hover:bg-gray-50">
              <FileContent data={data.file_content} />
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default Content;
