import {
  CheckIcon,
  ClipboardDocumentIcon,
  MapIcon,
  PuzzlePieceIcon,
  SparklesIcon,
} from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import GeneralTable from './GeneralTable';
import SimpleLoader from '../../../components/Loader/SimpleLoader';
import { getGeneralStats } from '../../../services/stats/statsService';
import { useTranslation } from 'react-i18next';

export default function GeneralStats({ isAdmin, user }) {
  const [selectedSchool, setSelectedSchool] = useState(null);
  const [generalStats, setGeneralStats] = useState([]);
  const [savedCardsDataForAllSchools, setSavedCardsDataForAllSchools] =
    useState([]);
  const [statsLoading, setStatsLoading] = useState(true);
  const [t] = useTranslation();

  useEffect(() => {
    if (isAdmin === false && user?.organization) {
      getGeneralStats({
        organization: user?.organization,
      })
        .then((res) => {
          setGeneralStats(res?.result);
        })
        .catch(() => {})
        .finally(() => {
          // setLoading(false);
          setSelectedSchool(user?.organization);
          setStatsLoading(false);
        });
    }
    if (isAdmin === true && user?.organization) {
      getGeneralStats({})
        .then((res) => {
          setGeneralStats(res?.result);
          setSavedCardsDataForAllSchools(res?.result);
        })
        .catch(() => {})
        .finally(() => {
          // setLoading(false);
          setSelectedSchool('all');
          setStatsLoading(false);
        });
    }

    return () => {};
  }, [isAdmin, user]);

  return (
    <div className='flex flex-col gap-4'>
      {statsLoading ? (
        <div className='flex flex-row justify-center align-middle h-36 border rounded-xl'>
          <SimpleLoader className='h-12' />
        </div>
      ) : (
        <div className='grid lg:grid-cols-5 md:grid-cols-2 grid-cols-1 gap-4 text-white'>
          <div
            className='rounded-xl bg-orange-500 flex flex-col 
              gap-2 p-4 w-full justify-between'
          >
            <div className='text-2xl font-bold'>Roadmap Generated </div>
            <div className='flex flex-row justify-between gap-2'>
              <div className='font-semibold'>
                <span className='text-4xl'>
                  {generalStats?.students_using_roadmap}
                </span>{' '}
                {t('stats.students')}
              </div>
              <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
                <MapIcon className='h-6 text-orange-500' />
              </div>
            </div>
          </div>
          <div
            className='rounded-xl bg-error flex flex-col 
              gap-2 p-4 w-full justify-between'
          >
            <div className='text-2xl font-bold'>Roadmap in progress</div>
            <div className='flex flex-row justify-between gap-2'>
              <div className='font-semibold'>
                <span className='text-4xl'>
                  {generalStats?.roadmap_tasks_in_progress}
                </span>{' '}
                {t('stats.students')}
              </div>
              <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
                <SparklesIcon className='h-6 text-error' />
              </div>
            </div>
          </div>
          <div
            className='rounded-xl bg-yellow-500 flex flex-col 
              gap-2 p-4 w-full justify-between'
          >
            <div className='text-2xl font-bold'>
              {t('stats.students_passed_tests')}{' '}
            </div>
            <div className='flex flex-row justify-between gap-2'>
              <div className='font-semibold'>
                <span className='text-4xl'>
                  {generalStats?.students_using_tests}
                </span>{' '}
                {t('stats.students')}
              </div>
              <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
                <CheckIcon className='h-6 text-yellow-500' />
              </div>
            </div>
          </div>

          <div
            className='rounded-xl bg-success flex flex-col 
              gap-2 p-4 justify-between'
          >
            <div className='text-2xl font-bold'>
              {t('stats.projects_accepted')}
            </div>
            <div className='flex flex-row justify-between gap-2'>
              <div className='font-semibold'>
                <span className='text-4xl'>
                  {generalStats?.projects_accepted}
                </span>{' '}
                {t('stats._projects')}
              </div>
              <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
                <PuzzlePieceIcon className='h-6 text-success' />
              </div>
            </div>
          </div>

          <div
            className='rounded-xl bg-primary flex flex-col 
              gap-2 p-4 w-full justify-between'
          >
            <div className='text-2xl font-bold'>
              {t('stats.courses_finished')}{' '}
            </div>
            <div className='flex flex-row justify-between gap-2'>
              <div className='font-semibold'>
                <span className='text-4xl'>
                  {generalStats?.courses_finished}
                </span>{' '}
                {t('stats.courses')}
              </div>
              <div className='rounded-full h-10 w-10 bg-white flex flex-col justify-center items-center'>
                <ClipboardDocumentIcon className='h-6 text-primary' />
              </div>
            </div>
          </div>
        </div>
      )}
      <GeneralTable
        selectedSchool={selectedSchool}
        setSelectedSchool={setSelectedSchool}
        setStatsLoading={setStatsLoading}
        setGeneralStats={setGeneralStats}
        savedCardsDataForAllSchools={savedCardsDataForAllSchools}
        isAdmin={isAdmin}
        user={user}
      />
    </div>
  );
}
