import React, { useState, useEffect } from 'react';
import { Button } from '../../../../../components/forms/Buttons/Button';
import Input from '../../../../../components/forms/Inputs/Input';
import CustomDialog from '../../../../../components/modals/CustomDialog/CustomDialog';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import TextArea from '../../../../../components/forms/Inputs/TextArea';
import { patchCourse, getCourses } from '../../../../../services/courses/courseService';

import { PencilSquareIcon } from '@heroicons/react/24/outline';

const Edit = ({ refreshData, course }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [keyFacts, setKeyFacts] = useState(course?.key_facts || ['']);
  const [keyFactError, setKeyFactError] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const onClose = () => {
    reset();
    setKeyFacts(course?.key_facts || ['']);
    setKeyFactError(false);
    setOpen(false);
  };

  const handleAddKeyFact = () => {
    if (keyFacts.length < 3) {
      setKeyFacts([...keyFacts, '']);
    }
  };

  const handleRemoveKeyFact = (index) => {
    setKeyFacts(keyFacts.filter((_, i) => i !== index));
  };

  const handleKeyFactChange = (index, value) => {
    const updatedKeyFacts = [...keyFacts];
    updatedKeyFacts[index] = value;
    setKeyFacts(updatedKeyFacts);
    setKeyFactError(false);
  };

  const onSubmit = async (data) => {
    const filteredKeyFacts = keyFacts.filter((fact) => fact.trim() !== '');
    if (filteredKeyFacts.length === 0) {
      setKeyFactError(true);
      return;
    }

    const payload = {
      ...data,
      key_facts: filteredKeyFacts,
      uuid: course?.uuid || 'course?.uuid',
      duration: parseInt(data.duration, 10),
    };

    try {
      await patchCourse(payload);
      refreshData();
      onClose();
    } catch (error) {
      console.error('Error saving course:', error);
    }
  };

  useEffect(() => {
    if (course?.uuid) {
      getCourses({ uuid: course.uuid }).then((data) => {
        setKeyFacts(data?.key_facts || ['']);
      });
    }
  }, [course]);

  return (
    <>
      <PencilSquareIcon
        className="w-8 h-8 p-1 text-primary border rounded-full bg-white hover:bg-slate-200 cursor-pointer"
        onClick={() => setOpen(true)}
      />
      <CustomDialog isOpen={open} closeModal={onClose}>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col gap-4 pt-4"
        >
          <Input
            errorMsg={errors.name?.message}
            required
            label="Title"
            defaultValue={course?.name || ''}
            {...register('name', {
              required: t('Title is a required field'),
            })}
          />
          <TextArea
            errorMsg={errors.description?.message}
            required
            rows={15}
            label="Description"
            defaultValue={course?.description || ''}
            {...register('description', {
              required: t('Description is a required field'),
            })}
          />
          <Input
            errorMsg={errors.duration?.message}
            required
            label="Duration"
            type="number"
            defaultValue={course?.duration || ''}
            {...register('duration', {
              required: t('Duration is a required field'),
              valueAsNumber: true,
              validate: (value) =>
                value > 0 || t('Duration must be a positive number'),
            })}
          />
          <div className="flex flex-col gap-2">
            <label className="font-semibold mb-2">
              Key Facts<span className="text-red-500"> *</span>
            </label>
            {keyFacts.map((fact, index) => (
              <div key={index} className="flex items-center gap-2">
                <Input
                  value={fact}
                  onChange={(e) => handleKeyFactChange(index, e.target.value)}
                  placeholder={t(`Key Fact ${index + 1}`)}
                />
                <Button
                  text="-"
                  color="danger"
                  onClick={() => handleRemoveKeyFact(index)}
                  type="button"
                  disabled={keyFacts.length === 1}
                />
              </div>
            ))}
            {keyFactError && (
              <p className="text-red-500 text-sm">
                {t('At least one Key Fact is required.')}
              </p>
            )}
            <Button
              text="+ Add Key Fact"
              onClick={handleAddKeyFact}
              type="button"
              color="primary"
              disabled={keyFacts.length >= 3}
            />
          </div>
          <div className="flex flex-row justify-end gap-2">
            <Button
              color="secondary"
              text={t('buttons.cancel')}
              onClick={onClose}
              type="button"
            />
            <Button color="success" text={t('buttons.save')} type="submit" />
          </div>
        </form>
      </CustomDialog>
    </>
  );
};

export default Edit;
